/*==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import React, { ChangeEvent, Fragment } from "react";
import { Navigate } from "react-router-dom";
import { LazyComponentProps, trackWindowScroll } from "react-lazy-load-image-component";
//UI
import {
  StyledGrid,
  StyledChip,
  StyledSelect,
  StyledPagination,
  StyledButton,
  StyledTypography,
  StyledFormControl,
  StyledLink,
} from "../../elements";
import { commonUtil } from "@hcl-commerce-store-sdk/utils";
import { Divider, Hidden } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import { East } from "@mui/icons-material";
/**
 * Catalog entry list widget
 *  @summary Displays Product Card List in Grid Layout.
 * `@prop {any} props` have following properties:
 * `@property {String} cid(required)` Unique identifier used for layouts.
 * `@property {String} categoryId(required)` Category unique identifier.
 * `@property {String} searchTerm` The input string for search.
 * `@property {any} translation(required)` The translation JSON object for i18n.
 * `@property {any} ProductCards(required)` The wrapper component to display product list.
 * `@property {any} formattedPriceDisplay(required)` The wrapper element use to display price on product grid layout.
 * `@property {any} sortOptions(required)` The options to sort the products like Name, Brand etc.
 * `@property {any} onSortOptionChange `The function will sort the products like Name, Brand etc.
 * `@property {any} SEARCH(required)` The constant variable - "bySearchTerm".
 * `@property {any} pageLimit(required)`  The number of products to dislay in a single page for pagination.
 * `@property {any} selectedFacets(required)` The labels use to display selected facet name.
 * `@property {any} clearPriceFacet(required)` The handler to clear the price facets.
 * `@property {any} onPageChange(required)` The handler to go to next page - pagination.
 * `@property {any} isValidUrl(required)` The function will return true if URL is valid.
 */
interface CatalogEntryListWidgetProps extends LazyComponentProps {
  cid: string;
  categoryId?: string;
  searchTerm?: string;
  isValidUrl: any;
  onPageChange: any;
  clearPriceFacet: any;
  onSortOptionChange: any;
  onClearAll: any;
  onFacetRemove: any;
  priceSelected: any;
  selectedFacets: any;
  productListTotal: any;
  selectedSortOption: any;
  pageLimit: any;
  productList: any;
  sortOptions: any;
  selectedPageOffset: any;
  suggestedKeywords: any;
  SEARCH: any;
  formattedPriceDisplay: any;
  ProductCards?: any;
  translation?: any;
  selectFacetRemove: boolean;
}
/**
 * Product Grid component
 * displays catalog entry listing, pagination and selected facets
 * @param props
 */
const CatalogEntryListWidget: React.FC<CatalogEntryListWidgetProps> = (props: any) => {
  const {
    priceSelected,
    selectedFacets,
    selectedSortOption,
    sortOptions,
    selectedPageOffset,
    suggestedKeywords,
    SEARCH,
    formattedPriceDisplay,
    ProductCards,
    translation,
    onSortOptionChange,
    productListTotal,
    pageLimit,
    cid,
    onFacetRemove,
    onClearAll,
    categoryId,
    searchTerm,
    isValidUrl,
    onPageChange,
    clearPriceFacet,
    productList,
    selectFacetRemove,
  } = props;
  const pageCountTotal: number = Math.ceil(productListTotal / pageLimit);
  const selectedPage = Math.floor(selectedPageOffset / pageLimit) + 1;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const goToNextPage = () => {
    const nextPageOffset = selectedPageOffset + pageLimit;
    // Asegúrate de que no exceda el total de productos
    if (nextPageOffset < productListTotal) {
      onPageChange(nextPageOffset); // Actualizado para pasar el nuevo offset directamente
    }
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    onPageChange(value); // Asumiendo que onPageChange actualiza el estado o realiza la lógica necesaria
  };

  return (
    <div className="product-listing-container productListingWidget left-margin-3 top-margin-3">
      {productListTotal === 0 && searchTerm !== "" && !selectFacetRemove ? (
        <div id={`productGrid_div_18_${cid}`} className="suggested-keywords">
          <h4 id={`productGrid_p_19_${cid}`}>{translation.ProductGridLabelsnoMatches}</h4>
          <p id={`productGrid_p_21_${cid}`}>{translation.ProductGridLabelssearchAgain}</p>
          {suggestedKeywords?.length > 0 && (
            <>
              {translation.ProductGridLabelssuggestion}
              <br />
              {suggestedKeywords?.map((keyword: string, index: number) => (
                <StyledLink
                  key={keyword}
                  to={`${SEARCH}?searchTerm=${commonUtil.encodeURLParts(keyword)}`}
                  className="suggestion-link"
                  id={`productGrid_a_22_${index}_${cid}`}
                  testId={`suggestedKeywords_${keyword}`}>
                  {keyword} <br />
                </StyledLink>
              ))}
            </>
          )}
        </div>
      ) : null}
      {/* Search result is 1, then go to PDP directly */}
      {productListTotal === 1 &&
        isValidUrl(productList) &&
        searchTerm !== "" &&
        Object.keys(selectedFacets)?.length === 0 &&
        !priceSelected &&
        !selectFacetRemove && <Navigate replace to={productList[0].seo.href} />}

      {productListTotal > 1 && isMobile ? (
        <StyledGrid container className="bottom-margin-2" spacing={2}>
          <StyledGrid container className="left-margin-2" alignItems="center" spacing={2} style={{ display: "grid" }}>
            <StyledGrid
              container
              item
              style={{
                width: "auto",
                alignItems: "center",
                display: "flex",
                flexFlow: "wrap",
                justifyContent: "space-between",
                borderTop: "1px solid #DBDBDB",
                paddingLeft: "0px",
                marginTop: "10px",
              }}>
              <StyledGrid item>
                <StyledFormControl variant="outlined">
                  <StyledButton
                    startIcon={<TuneIcon />}
                    testId="filter-button"
                    color="secondary"
                    // onClick={() => showSignInPage(true)}
                    className="filter-products-button">
                    {"FILTROS"}
                  </StyledButton>
                </StyledFormControl>
              </StyledGrid>
              <StyledGrid item className="widthRelevancia">
                <StyledFormControl variant="outlined">
                  <StyledSelect
                    style={{ height: "48px" }}
                    data-testid="list-sort-option"
                    id={`productGrid_select_6_${cid}`}
                    value={selectedSortOption}
                    native
                    onChange={(event: any) => onSortOptionChange(event)}
                    fullWidth>
                    {sortOptions?.map((option: any, index: number) => (
                      <option value={option.value} key={option.value} id={`productGrid_option_7_${index}_${cid}`}>
                        {translation.optiontranslationKey[index]}
                      </option>
                    ))}
                  </StyledSelect>
                </StyledFormControl>
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
          <StyledGrid
            container
            className="left-margin-2"
            style={{ borderTop: "1px solid #DBDBDB", paddingTop: "10px", marginTop: "10px" }}>
            <StyledGrid item>
              <StyledTypography style={{ fontSize: "14px" }}>
                {categoryId !== ""
                  ? translation.ProductGridLabelsproductFound
                  : translation.ProductGridLabelsproductSearchFound}
              </StyledTypography>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      ) : productListTotal > 1 && !isMobile ? (
        <StyledGrid
          container
          className="bottom-margin-4"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}>
          <StyledGrid item>
            <StyledTypography>
              {categoryId !== ""
                ? translation.ProductGridLabelsproductFound
                : translation.ProductGridLabelsproductSearchFound}
            </StyledTypography>
          </StyledGrid>

          <StyledGrid container item style={{ width: "auto", alignItems: "center" }}>
            <StyledGrid item>
              <StyledFormControl variant="outlined">
                <StyledSelect
                  data-testid="list-sort-option"
                  id={`productGrid_select_6_${cid}`}
                  value={selectedSortOption}
                  native
                  onChange={(event: any) => onSortOptionChange(event)}
                  fullWidth>
                  {sortOptions?.map((option: any, index: number) => (
                    <option value={option.value} key={option.value} id={`productGrid_option_7_${index}_${cid}`}>
                      {translation.optiontranslationKey[index]}
                    </option>
                  ))}
                </StyledSelect>
              </StyledFormControl>
            </StyledGrid>
          </StyledGrid>
        </StyledGrid>
      ) : null}

      {/* Facet selection listing */}
      {Object.keys(selectedFacets).length > 0 || priceSelected ? (
        <StyledGrid container className="bottom-margin-3" spacing={1}>
          {(Object.keys(selectedFacets).length > 0 || (priceSelected && Object.keys(selectedFacets).length > 0)) && (
            <StyledGrid item>
              <StyledButton testId="clear-all-facets" variant="text" className="left-margin-1">
                <StyledLink
                  onClick={(event) => onClearAll(event)}
                  to=""
                  className="clear-all"
                  testId="clear-all-facets">
                  {translation.ProductGridActionsclearAll}
                </StyledLink>
              </StyledButton>
            </StyledGrid>
          )}
        </StyledGrid>
      ) : null}

      {/* Product listing and pagination */}
      {ProductCards}
      <Divider style={{ marginTop: "10px" }} />
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
          <StyledPagination
            count={pageCountTotal}
            shape="rounded"
            page={selectedPage}
            onChange={handleChange}
            hidePrevButton
          />
        </div>
        <button
          onClick={goToNextPage}
          style={{
            background: "none",
            border: "none",
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "1rem",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
          }}>
          Siguiente <East style={{ verticalAlign: "middle" }} />
        </button>
      </div>
    </div>
  );
};
export default trackWindowScroll(CatalogEntryListWidget);

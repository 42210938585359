/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */

//Standard libraries
import { useMemo } from "react";
//Custom libraries
import { useOrderItemTable } from "../../../_foundation/hooks/use-order-item-table";
//HCL SDK
import { CustomTable, withCustomTableContext } from "@hcl-commerce-store-sdk/react-component";
import { PAGINATION } from "../../../constants/common";
import { makeStyles } from "@mui/styles";

const OrderItemTableComponent = (props: any) => {
  const { actionData, columns, data, t, labels, detailPanel, isCartView /* panelExpanderComps */ } =
    useOrderItemTable(props);
  /* Mini Cart Content Styles */
  const MCTStyles = makeStyles((theme) => ({
    miniCartTable: {
      border: 0,
      "& thead": {},
      "& .MuiAvatar-root": {
        width: theme.spacing(13),
        height: "100%",
        backgroundColor: "white",
        "& img": {
          height: "104px",
        },
      },
      "& tbody": {
        display: "flex",
        flexDirection: "column",
        /* background: "#FAFBFB", */
        "& tr": {
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "row",
          "& td": {
            paddingLeft: "0px",
            color: "#4B4B4B",
            font: "normal normal normal 15px/18px Rubik",
            "& a": {
              font: "normal normal 600 15px/18px Rubik",
              color: "#272633",
              letterSpacing: "0px",
              opacity: 1,
              marginBottom: "15px",
              "@media screen and (max-width: 40em)": {
                marginBottom: "0",
              },
              "&:hover": {
                textDecoration: "none",
                font: "normal normal 600 15px/18px Rubik",
                color: "#272633",
                letterSpacing: "0px",
                opacity: 1,
              },
            },
            "& p": {
              marginLeft: "1px",
            },
          },

          "&:nth-child(odd)": {
            backgroundColor: "transparent",
          },
          "@media screen and (max-width: 40em)": {
            "& thead": {
              display: "none",
            },
            "& .MuiAvatar-root": {
              width: "100%",
              height: "auto",
            },
            "& td.pivoted": {
              paddingLeft: "0 !important",
            },
            "& td .tdBefore": {
              display: "none",
            },
          },
        },
      },
    },
  }));

  const classesMiniCartTable = MCTStyles();

  const CTStyles = makeStyles((theme) => ({
    CartTable: {
      "& .MuiAvatar-root img": {
        width: "20vw",
        height: "20vw",
        objectFit: "contain",
        maxWidth: theme.spacing(18),
        maxHeight: theme.spacing(18),
        backgroundColor: "transparent",
        padding: "0px !important",
      },
      "&:nth-child(odd)": {
        backgroundColor: "transparent",
      },
      "& tbody": {
        "& tr": {
          verticalAlign: "top",
          width: "100%",
          "&:nth-child(odd)": {
            backgroundColor: "transparent",
          },
          "& td": {
            padding: "5px",
            "& a": {
              "@media screen and (max-width: 40em)": {
                backgroundColor: "transparent !important",
                marginBottom: "0",
              },
              "&:hover": {
                textDecoration: "none",
                font: "normal normal normal 0.925rem Rubik",
                color: "#272633",
              },
            },
          },
          "&:not(:nth-last-child(2))": {
            borderBottom: isCartView ? "1px solid #ddd" : "none",
            marginLeft: "14px",
            marginRight: "14px",
            width: "calc(100% - 28px)",
          },
        },
      },

      "@media screen and (max-width: 1200px)": {
        "& td": {
          paddingLeft: "0 !important",
          paddingTop: "0 !important",
        },
      },
      "@media screen and (max-width: 640px)": {
        "& .MuiAvatar-root img": {
          /* height: "100%", */
          backgroundColor: "transparent",
        },
        "& tbody": {
          backgroundColor: "transparent !important",
          "& tr": {
            /* display: "table-row-group", */
            display: "inline-table",
            flexDirection: "row",
            backgroundColor: "transparent",
            marginBottom: "0px !important",
            "&:not(:nth-last-child(2))": {
              borderBottom: isCartView ? "1px solid #ddd" : "none",
            },
            "&:nth-last-child(2)": {
              marginBottom: "0px !important",
              marginLeft: "19px",
              marginRight: "14px",
              width: "calc(100% - 33px)",
            },

            "& td": {
              padding: "0px !important",
              "&::after": {
                content: '""',
                display: "block",
                height: "0px",
                backgroundColor: "transparent",
              },
              "&:nth-child(odd)": {
                backgroundColor: "transparent",
              },
              "& a": {
                font: "0.925rem !important",
                color: "#272633",
                letterSpacing: "0px",
                opacity: 1,
                textAlign: "left",
                "@media screen and (max-width: 40em)": {
                  backgroundColor: "transparent !important",
                  marginBottom: "0",
                },
                "&:hover": {
                  textDecoration: "none",
                  font: "0.925rem !important",
                  color: "#272633",
                  letterSpacing: "0px",
                  opacity: 1,
                  textAlign: "left",
                },
              },
              "& p": {},
            },

            "@media screen and (max-width: 640px)": {
              "& td:nth-child(odd)": {
                backgroundColor: "transparent !important",
              },
              "& thead": {
                display: "none",
              },

              "& td.pivoted": {
                paddingLeft: "0px !important",
              },
              "& td .tdBefore": {
                display: "none",
              },
            },
          },
        },
      },
    },
  }));

  const classesCartTable = CTStyles();

  const { miniCartView: c, className: n, outerClassName: oc } = props;
  const className = [n, c ? classesMiniCartTable.miniCartTable : classesCartTable.CartTable].filter(Boolean).join(" ");
  const outerClassName = c ? "" : oc;
  const paginationData = c
    ? undefined
    : {
        clientSide: true,
        t,
        sizes: PAGINATION.sizes,
        labels: {
          ofTotalCount: "commonTable.ofTotalCount",
        },
      };

  return (
    <CustomTable
      {...{
        t,
        labels,
        data,
        columns,
        className,
        detailPanel: c ? null : detailPanel,
        showPanelOnMobile: true,
        outerClassName,
        paginationData,
        /* panelExpanderComps, */
        actionData,
      }}
    />
  );
};

export const OrderItemTable = (props) => {
  const WithCTCtx = useMemo(() => withCustomTableContext(OrderItemTableComponent), []);
  return <WithCTCtx {...props} />;
};

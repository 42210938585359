// src/redux/reducers/cartReducer.ts
import { CartActions, SET_CART_VIEW } from "../actions/is-cart";

export interface CartState {
  isCartView: boolean;
}

const initialState: CartState = {
  isCartView: false,
};

const cartReducer = (state = initialState, action: CartActions): CartState => {
  switch (action.type) {
    case SET_CART_VIEW:
      return {
        ...state,
        isCartView: action.payload,
      };
    default:
      return state;
  }
};

export default cartReducer;

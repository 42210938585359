/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "@mui/styled-engine-sc";
import Container from "@mui/material/Container";

const CustomContainer = (props: any) => <Container maxWidth="xl" {...props} />;

const StyledContainer = styled(CustomContainer)`
  ${({ theme }) => `

    &.expanded-menu {
      width:inherit;
      height:${theme.spacing(5)};
      text-align: center;

      &.MuiContainer-root {
        width:inherit;
        margin:0;
        padding:0;
      }
    }

    &.container-home-page-layout {
      padding-left: 0px;
      padding-right: 0px;
      display: flex;
      justify-content: center;
    }

    &.container-product-page-layout {
      padding-left: 106px;
      padding-right: 116px;
      max-width: 1440px;

    @media (max-width: 648px) {
      padding-left: 14px;
      padding-right: 14px;
    }
  `}
`;

export { StyledContainer };

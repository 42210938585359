import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import { SvgIcon } from "@mui/material";
import styled from "styled-components";

export const HeaderAccountNameTextContainer = styled((props: any) => <StyledGrid {...props} />)`
  ${({ theme }) => `
    align-self: center;

    .account-welcome{
      color: #ffffff !important;
      font-size: 10px;
      transform: translateY(8px);
      max-width: 35px;
      overflow: unset;

      position: relative;
      left: -50%;
      width: auto;
    }

    `}
`;

export const IconContainer = styled((props: any) => <SvgIcon {...props} />)`
  ${({ theme }) => `
      transform: translateY(8px);



    `}
`;

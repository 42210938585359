/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "@mui/styled-engine-sc";
import Pagination from "@mui/material/Pagination";
import { dimensions } from "../../themes/variables";

const buttonSize = dimensions.pagination.button;

/**
 * Styled component on @material-ui
 * @see https://material-ui.com/api/pagination/
 */
const StyledPagination = styled(({ ...props }) => <Pagination {...props} />)`
  ${({ theme }) => `
    margin: 20px 0;

    ul {
      li:first-child,
      li:last-child {
        button {
          border: 2px solid ${theme.palette.text.primary};
          color: ${theme.palette.text.primary};
          height: ${buttonSize}px;
          width: ${buttonSize}px;
          background-color: white;

          &:hover {
            background-color: ${theme.palette.grey[200]};
          }
        }
      }
    }

    .MuiPaginationItem-root {
      color: ${theme.palette.text.primary};
      border: 2px solid ${theme.palette.text.primary};
      height: ${buttonSize}px;
      width: ${buttonSize}px;
      background-color: white;

      &:hover {
        background-color: ${theme.palette.grey[200]};
      }
    }

    .MuiPaginationItem-page.Mui-selected {
      border: 0;
      background-color: black; // Fondo de la página actual
      color: white;
      &:hover {
        background-color: ${theme.palette.grey[200]};
        border: 2px solid ${theme.palette.text.primary};
        color: ${theme.palette.text.primary};
      }
    }

    .MuiPaginationItem-ellipsis {
      color: ${theme.palette.text.secondary};
    }
    `}
`;

export { StyledPagination };

import React from "react";
import styled from "@mui/styled-engine-sc";
import { StyledPaper } from "../paper";
import { StyledGrid } from "../grid";
import Typography from "@mui/material/Typography";

const StyledButtonBoxWrapper = styled(({ ...props }) => <StyledPaper {...props} />)`
  ${({ theme }) => `
   display: flex;
   padding: ${theme.spacing(3)};
   border: 1px dashed #CBCBCB;
   border-radius: 4px;
   box-shadow: none;
   cursor: pointer;
   justify-content: center;
   align-items: center;

   &.disabled {
     h6, p {
       color: ${theme.palette.text.disabled};
     }

     .MuiSvgIcon-root {
       color: ${theme.palette.text.disabled};
     }
   }
 `}
`;

const StyledLabel = styled(Typography)`
  font: normal normal medium 15px/21px Rubik;
  letter-spacing: 0px;
  color: #272633;
  margin-left: 8px;
`;

interface ButtonBoxProps {
  msg: string;
  icon: any;
  button: string;
  disabled?: boolean;
  buttonAction?: any;
  testId: string;
}

const CustomStyledIconLabel = styled(({ icon, label, ...props }) => (
  <div {...props}>
    {icon}
    <StyledLabel>{label}</StyledLabel>
  </div>
))`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function StyledButtonBox({ msg, icon, button, disabled, buttonAction, testId }: ButtonBoxProps) {
  return (
    <StyledButtonBoxWrapper
      className={disabled ? "disabled" : ""}
      onClick={disabled ? undefined : buttonAction}
      data-testid={testId}>
      <StyledGrid container direction="row" justifyContent="center" alignItems="center">
        <CustomStyledIconLabel icon={icon} label={msg} />
      </StyledGrid>
    </StyledButtonBoxWrapper>
  );
}

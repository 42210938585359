import styled from "@mui/styled-engine-sc";
import { useTheme } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar, A11y } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const StyledSlideContainer = styled("div")`
  ${({ theme }) => `
position: relative;
width: 100%;
height: auto;
padding-bottom: 10px;
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide p {
  font: normal normal normal 14px/17px Rubik;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
}
.swiper-slide span {
  text-align:left;
  font: normal normal 600 15px/18px Rubik;
  letter-spacing: 0px;
}
.swiper-slide img {
  maxWidth: 100%;
  maxHeight: 100%;
  width: auto;
  object-fit: cover;
  borderRadius: 0;
  background: transparent;
}
.swiper-scrollbar.swiper-scrollbar-horizontal {
  height: 8px;
}
.swiper-scrollbar {
  bottom: 0;
  height: 8px;
  .swiper-scrollbar-drag {
    border-radius: 10px;
    height: 8px;
    background: white;
  }
}
.swiper-button-next, .swiper-button-prev {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
}

.swiper-button-next:hover, .swiper-button-prev:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 25px !important;
}
`}
`;

const StyledProductRecommendationSlider = ({ slidesList }) => {
  return (
    <StyledSlideContainer>
      <Swiper
        modules={[Navigation, Scrollbar, A11y]}
        slidesPerView={5}
        navigation
        scrollbar={{ draggable: true }}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 2,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        }}>
        {slidesList.map((slideContent, index) => (
          <SwiperSlide key={index} className="swiper-slide">
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledSlideContainer>
  );
};

export { StyledProductRecommendationSlider };

// src/redux/actions/is-checkout.ts
export const SET_CHECKOUT_VIEW = "SET_CHECKOUT_VIEW";

// Define una interfaz para el tipo de acción
export interface SetCheckoutViewAction {
  type: typeof SET_CHECKOUT_VIEW;
  payload: boolean;
}

// Función creadora de acciones
export function setCheckoutView(isCheckoutView: boolean): SetCheckoutViewAction {
  return {
    type: SET_CHECKOUT_VIEW,
    payload: isCheckoutView,
  };
}

// Tipo de exportación para ser utilizado en el reducer
export type CheckoutActions = SetCheckoutViewAction;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Scrollbar, A11y } from "swiper/modules";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { makeStyles } from "@mui/styles";

const baseUrl = "";

const StyledSlideContainer = styled.div`
  ${({ theme }) => `
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 40px;

    @media (max-width: 960px) {
      margin-top: 20px;
    }

    .swiper {
      padding-bottom: 70px;
      @media (max-width: 960px) {
        padding-bottom: 40px;
        padding-left: 10px;
      }
    }

    .swiper-container {
      width: 100%;
      height: 100%;
    }

    .swiper-wrapper {
      display: flex;
      justify-content: flex-start;
    }

    .swiper-slide {
      flex-shrink: 0;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      color: white;
      flex-direction: row;
      @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
      }
      @media (max-width: 1260px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .swiper-slide img {
      max-width: 100%;
      max-height: 100%;
      background-color: white;
      width: auto;
      height: 300px;
      object-fit: cover;
      border-radius: 0px;
      margin-right: 20px;
      margin-left: 20px;

      @media screen and (max-width: 1260px) {
        margin-right: 20px;
        margin-left: 0px;

        @media (max-width: 600px) {
          width: 180px;
          height: 238px;
        }
      }

      @media screen and (max-width: 1220px) {
        margin-right: 0;
        margin-bottom: 0px;
      }

      @media screen and (max-width: 960px) {
        width: 280px;
      }

      @media screen and (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 0px;
        width: 180px;
      }
    }


    .swiper-scrollbar.swiper-scrollbar-horizontal {
      height: 8px;
    }
    .swiper-horizontal > .swiper-scrollbar, .swiper-scrollbar.swiper-scrollbar-horizontal {
      bottom: 20px;
    }

    .swiper-scrollbar {
      bottom: 0;
      height: 8px;
      .swiper-scrollbar-drag {
        border-radius: 10px;
        height: 8px;
        background: white;
      }
    }

    .swiper-pagination {
      margin-top: 20px;
    }
    .swiper-pagination-bullet {
      width: 16px;
      height: 16px;
      background-color: gray;
      border: 1px solid white;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background-color: white;
      opacity: 1;
    }
  `}
`;

const StyledSlideContent = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;

    .slide-title {
      font: normal normal 600 24px/28px Rubik;
      margin-bottom: 5px;
      color: white;
      @media (max-width: 960px) {
        font: normal normal 600 14px/17px Rubik;
        margin: 10px auto;
      }
    }

    .slide-description {
      margin-bottom: 10px;
      font: normal normal 600 20px/24px Rubik;
      letter-spacing: 0px;
      color: #FFFFFF;
      @media (max-width: 960px) {
        text-align: left;
        font: normal normal 600 14px/17px Rubik;
      }
    }

    .buttonStyles {
      width: 50%;
      margin-top: 20px;
      padding: 15px;
      padding-left: 25px;
      padding-right: 25px;
      background-color: white;
      color: #533278;
      border-radius: 0px;
      font: normal normal bold 16px/19px Rubik;
      letter-spacing: 0px;
      text-transform: uppercase;
      opacity: 1;
      &:hover {
        background-color: #533278;
        color: white;
      }
      @media (max-width: 1480px) {
        width: 70%;
      }
      @media (max-width: 1260px) {
        display: none;
      }
    }
  `}
`;

const StyledFeaturedRecommendationSlider = ({ slidesList, widget }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1260);
  const navigate = useNavigate();

  const productCardStyles = makeStyles((theme) => ({
    contenedor: {
      "@media screen and (max-width: 960px)": {
        backgroundColor: "#221C32",
        width: "280px",
        minHeight: "95.5px",
      },
      "@media screen and (max-width: 600px)": {
        width: "180px",
      },
    },
    paddingTexto: {
      "@media screen and (max-width: 960px)": {
        paddingTop: "10px",
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    paddingPrice: {
      "@media screen and (max-width: 960px)": {
        fontWeight: "bold",
        paddingLeft: "8px",
        paddingRight: "8px",
        margin: "0px",
        position: "absolute",
        bottom: 0,
        marginBottom: "19px",
      },
    },
  }));

  const classes = productCardStyles();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1260);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNavigation = (href) => {
    navigate(href);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <StyledSlideContainer>
      <Swiper
        modules={[Pagination, Scrollbar, A11y]} // Incluimos todos los módulos siempre
        slidesPerView={1}
        pagination={isMobile ? false : { clickable: true }}
        scrollbar={isMobile ? { draggable: true } : false}
        breakpoints={{
          320: {
            slidesPerView: 2,
            spaceBetween: 14,
          },
          415: {
            slidesPerView: 2.3,
            spaceBetween: 14,
          },
          960: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          1260: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}>
        {slidesList.map((slideContent, index) => {
          const product = slideContent.props.children.props.renderingContext.productInternal;
          const price = slideContent.props.children.props.renderingContext.eSpotDescInternal;

          return (
            <SwiperSlide key={index} className="swiper-slide">
              <div
                onClick={() => handleNavigation(`${baseUrl}${product.seo.href}`)}
                style={{ cursor: "pointer", lineHeight: "0px" }}>
                <img src={product.fullImage} alt={product.name} />
              </div>
              <StyledSlideContent
                onClick={() => handleNavigation(`${baseUrl}${product.seo.href}`)}
                className={classes.contenedor}
                style={{ cursor: "pointer" }}>
                <span className={classes.paddingTexto}>{product.name}</span>
                <p className={classes.paddingPrice}>{formatter.format(price.listPrice)}</p>
                {product.seo && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleNavigation(`${baseUrl}${product.seo.href}`);
                    }}
                    variant="contained"
                    className="buttonStyles"
                    style={{}}>
                    VER PRODUCTO
                  </Button>
                )}
              </StyledSlideContent>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </StyledSlideContainer>
  );
};

export { StyledFeaturedRecommendationSlider };

/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

import styled from "@mui/styled-engine-sc";

export const StyledProductImage = styled("img")`
  ${({ theme }) => `
  position: relative;
  max-width: 100%;

  &.thumbnail {
    /* width: 85px;
    height: 93px; */
    margin-top: 5px;

    &:hover {
      cursor: pointer;
    }

    &.selected {
      border: solid 1px ${theme.palette.primary.main};
      border-radius: 2px;
    }
  }
  `}
`;

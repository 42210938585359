import { useLocation } from "react-router";
import { useMemo } from "react";

import { paramCase } from "change-case";
import { useTranslation } from "react-i18next";
import ContentRecommendationWidget from "../../commerce-widgets/content-recommendation-widget";

interface EspotMzFCProps {
  espotName: string;
  variable1?: string | number;
  variable2?: string | number;
  variable3?: string | number;
  variable4?: string | number;
}

const EspotMzFC = ({ espotName }: EspotMzFCProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const crwProps = useMemo(
    () => ({
      widget: {
        id: `header-${paramCase(espotName ? espotName : "")}`,
        widgetName: "content-recommendation-widget",
        name: espotName ? espotName : "",
        properties: {
          emsName: espotName ? espotName : "",
        },
      },
      page: { name: "" },
    }),
    //Content is language sensitive, so listen to translation change to render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  if (location.pathname === "/checkout/print-order") return <></>;

  return <ContentRecommendationWidget {...crwProps} />;
};

export default EspotMzFC;

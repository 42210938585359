/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "@mui/styled-engine-sc";
import Accordion, { AccordionProps } from "@mui/material/Accordion";

interface StyledAccordionProps extends AccordionProps {
  testId: string;
}

const StyledAccordionWrapper = styled((props: any) => <Accordion {...props} />)`
  ${({ theme }) => `
    &.MuiAccordion-root.Mui-expanded {
      margin: 0;
      background-color: ${theme.palette.background.default};
      @media (max-width: 960px) {
        background-color: ${theme.palette.background.paper};
        padding-top: 10px;
        box-shadow: none;
      }
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      box-shadow: none;
      border-top: 1px solid #DBDBDB;
      &:before {
        opacity: 1;
        background-color: #DBDBDB;
      }
      &.shipment-group {
        margin: ${theme.spacing(2)} 0;
        border-radius: ${theme.shape.borderRadius}px;
        border: 1px solid  ${theme.palette.divider};
      }
    }
    &:before {
      background-color: #DBDBDB;
    }
    &.shipment-group {
      margin: ${theme.spacing(2)} 0;
      border-radius: ${theme.shape.borderRadius}px;
      border: 1px solid  ${theme.palette.divider};
    }
  `}
`;

const StyledAccordion: React.FC<StyledAccordionProps> = ({ testId, ...props }) => {
  return <StyledAccordionWrapper data-testid={testId} {...props} />;
};

export { StyledAccordion };

/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState } from "react";
//Custom libraries
import MegaMenuColumn from "./MegaMenuColumn";
//UI
import { StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import { Category } from "_foundation/types/category";
import { useMediaQuery, useTheme } from "@mui/material";
import { useSite } from "_foundation/hooks/useSite";
import styled from "styled-components";
import imageUrl from "../../assets/eaas-b2c-store/images/footer/americanExpress.svg";
import { makeStyles } from "@mui/styles";
import ContentRecommendationWidget from "components/commerce-widgets/content-recommendation-widget/content-recommendation-widget";
import EspotMzFC from "components/custom/eSpot/EspotMzFC";

interface MegaMenuProps {
  pages?: Category[];
  closeMegaMenu?: any;
}

/**
 * MegaMenu component
 * displays top category links in desktop/mobile view
 * @param props
 */
const MegaMenu: React.FC<MegaMenuProps> = ({ pages, closeMegaMenu }) => {
  const [expanded, setExpanded] = useState<string>("");
  const handleChange = (pageId: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? pageId : "");
  };
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up("sm"));
  const { mySite: site } = useSite();

  const useStyles = makeStyles((theme) => ({
    container: {
      "@media (max-width: 960px)": {
        width: "50%",
      },
    },
  }));

  const classesCategory = useStyles();

  const isMobile = useMediaQuery("(max-width:960px)");

  return (
    <StyledGrid>
      {isMobile ? (
        <StyledGrid container>
          <EspotMzFC espotName="categoriasMobile_jersey" />
        </StyledGrid>
      ) : (
        <>
          {pages?.map((page, index) => (
            <StyledGrid item xs={6} sm={6} md={3} lg={2} key={page.id} className={classesCategory.container}>
              <MegaMenuColumn
                page={page}
                levelExpanded={expanded}
                onChange={handleChange(page.id)}
                closeMegaMenu={closeMegaMenu}
                level={1}
                site={site}
                isTablet={!isMobile}
              />
            </StyledGrid>
          ))}
        </>
      )}
    </StyledGrid>
  );
};

export default MegaMenu;

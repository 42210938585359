/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

import styled from "@mui/styled-engine-sc";

export const StyledDesktopProductImage = styled("div")`
  ${({ theme }) => `

  .desktop-product-image {
    margin-left: 25px;
    height: 613px;
  }

  .lazy-load-image-background.opacity {

    transform: translateY(100px);
    opacity: 0;
  }

  .lazy-load-image-background.opacity.lazy-load-image-loaded {
 /*    transition: opacity 2s ease, transform 2s ease; */
    transform: translateY(0);
    opacity: 1;
  }

  `}
`;

/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import styled from "@mui/styled-engine-sc";

const StyledFooter = styled("footer")`
  ${({ theme }) => `
  background: ${theme.palette.primary.main};
  color: ${theme.palette.info.contrastText};
/*   margin-top: ${theme.spacing(7)}; */

  .footer-top {
    padding: ${theme.spacing(4)} ${theme.spacing(7.5)} 0px;
    margin-left: ${theme.spacing(8.5)}

    ${theme.breakpoints.down("sm")} {
      padding: ${theme.spacing(3.5)} ${theme.spacing(1.5)} 0 ${theme.spacing(1.5)};
    }
  }

  ${theme.breakpoints.down("sm")} {
    margin-top: ${theme.spacing(4)};
    .footer-top {
        padding: ${theme.spacing(3.5)} ${theme.spacing(1.5)} 0 ${theme.spacing(1.5)};
    }
  }

  .copyright {
    display: flex;
    ${theme.breakpoints.up("sm")} {
      padding-left:${theme.spacing(7.5)};
    }
    a {
      color: ${theme.palette.info.contrastText};
      text-decoration: none;
      &:hover {
        color: ${theme.palette.info.contrastText};
      }
      &:visited {
        color: ${theme.palette.info.contrastText};
      }
    }
  }

  .footer-logo {
    padding-bottom: ${theme.spacing(1)};
    display: flex;

    img {
      height: ${theme.spacing(3)};
    }
  }

  .footer-nav {
    line-height: 2;

    .show: {
      display: "block";
    }
    .hide: {
      display: "none";
    }

    ${theme.breakpoints.down("sm")} {
      border-top: 0.5px solid #878C8F;
      padding-top: 20px;
    }

    a {
      color: ${theme.palette.info.contrastText};
      text-decoration: none;
      &:hover {
        color: ${theme.palette.info.contrastText};
      }
      &:visited {
        color: ${theme.palette.info.contrastText};
      }
    }

  }

  .footer-nav2 {
    line-height: 2;

    a {
      color: ${theme.palette.info.contrastText};
      text-decoration: none;
      &:hover {
        color: ${theme.palette.info.contrastText};
      }
      &:visited {
        color: ${theme.palette.info.contrastText};
      }
    }

    div {
      padding-top: ${theme.spacing(0.5)};
    }
  }

  .footer-bottom {
    padding: ${theme.spacing(2)} 0;
    margin-left: ${theme.spacing(6)}
    ${theme.breakpoints.down("sm")} {
      padding: ${theme.spacing(1)} 0;
    }
  }

  .footer-social-link {
    background: ${theme.palette.primary.main};
    display: inline-block;
    height: ${theme.spacing(2.5)};
    width: ${theme.spacing(5)};
    margin-right: ${theme.spacing(0)};
    margin-top: ${theme.spacing(0)};
    position: relative;
    color: ${theme.palette.info.contrastText};

    &:hover {
      opacity: 0.9;
    }

    svg {
      font-size: ${theme.spacing(2)};
      position: absolute;
      cursor: pointer;
    }
  }

  .footer-payment-icon {
    background: ${theme.palette.primary.main};
    display: inline-block;
    margin-right: ${theme.spacing(2.5)};
    margin-top: ${theme.spacing(0)};
    position: relative;
    color: ${theme.palette.info.contrastText};

    &:hover {
      opacity: 0.9;
    }

    svg {
      font-size: ${theme.spacing(2)};
      position: absolute;
      cursor: pointer;
    }
  }

  .master-card {
    height: ${theme.spacing(2.5)};
    width: ${theme.spacing(4)};
  }
  .american-express {
    height: ${theme.spacing(2.3)};
    width: ${theme.spacing(6.5)};
  }
  .visa {
    height: ${theme.spacing(2)};
    width: ${theme.spacing(6)};
  }
  .paypal {
    height: ${theme.spacing(2)};
    width: ${theme.spacing(6.5)};
  }

  `}
`;

export { StyledFooter };

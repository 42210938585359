/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "@mui/styled-engine-sc";
import Grid from "@mui/material/Grid";

const ComponentWrapper = (props: any) => <Grid {...props} />;

const StyledGrid = styled(ComponentWrapper)`
  ${({ theme }) => `
    &.sidebar {
      ${theme.breakpoints.down("md")} {
        padding: 0;
      }
    }

    ${theme.breakpoints.down("sm")} {
      &.checkout-actions {
        /* flex-direction: column; */

        .MuiGrid-item {
          .MuiButtonBase-root {
            width: 100%;
          }
        }
      }
    }
    .container-cart-page-layout {
      padding-left: 0px;
      padding-right: 0px;
      max-width: 1440px;
      margin-bottom: 8px;
    }
    .container-product-page-layout{
      padding-left: 106px;
      padding-right: 106px;
      max-width: 1440px;

      @media (max-width: 648px) {
        padding-left: 14px;
        padding-right: 14px;
      }
    }
    #OrderConfirmationPage {
      .recommended-product-style-default{
        max-width: 1440px;
        margin: 0 auto;
        padding: 0;
      }
    }
  `}
`;

export { StyledGrid };

import styled from "@mui/styled-engine-sc";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar, A11y } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useEffect, useState } from "react";

const StyledSlideContainer = styled(({ ...props }) => <div {...props} />)`
  ${({ theme, scrollBarColor }) => `
position: relative;
width: 100%;
height: auto;
padding-bottom: 10px;

.swiper {
  padding-bottom: 110px;
  padding-left: 0px;
  padding-right: 0px;
  max-width: 1440px;
  ${theme.breakpoints.down("768")} {
    padding-bottom: 70px;
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  width: 249px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${theme.breakpoints.down("sm")} {
    width: 180px;
  }
}
.swiper-slide p {
  font: normal normal normal 14px/17px Rubik;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
  height: 34px;
}
.swiper-slide span {
  text-align:left;
  font: normal normal 600 15px/18px Rubik;
  letter-spacing: 0px;
}
.swiper-slide img {
  maxWidth: 100%;
  maxHeight: 100%;
  width: 249px;
  object-fit: cover;
  height: 329px;
  borderRadius: 0;
  background-color: white;
  ${theme.breakpoints.down("sm")} {
    width: 180px;
    height: 238px;
  }
  @media screen and (max-width: 390px): {
    width: 150px;
  },
}
.swiper-scrollbar.swiper-scrollbar-horizontal {
  height: 7px;
  bottom: 75px;
  ${theme.breakpoints.down("sm")} {
    padding-left: 12px;
    bottom: 60px;
  }
}
.swiper-scrollbar {
  bottom: 0;
  height: 7x;
  .swiper-scrollbar-drag {
    border-radius: 10px;
    height: 7px;
    background: ${scrollBarColor};
  }
}
.swiper-button-next, .swiper-button-prev {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  top: 35%;
}
.swiper-button-next {
  right: 0;
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-next {
  right: 0;
}

.swiper-button-prev {
  left: 0;
}

.swiper-button-disabled {
  display: none;
}

.swiper-button-next:hover, .swiper-button-prev:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 25px !important;
}

.swiper-slide .product-card .full-height .full-width .vertical-padding-2 span{
    color: #FFFFFF;
  }

`}
`;

const StyledProductRecommendationSlider = ({ slidesList, widget }) => {
  let scrollBarColor = "white";

  if (widget.name === "CartPageProductRecommendation") {
    scrollBarColor = "black";
  }

  return (
    <StyledSlideContainer scrollBarColor={scrollBarColor}>
      <Swiper
        modules={[Navigation, Scrollbar, A11y]}
        slidesPerView={1.7}
        navigation
        autoHeight={true}
        scrollbar={{ draggable: true }}
        spaceBetween={15}
        breakpoints={{
          350: {
            slidesPerView: 1.6,
            spaceBetween: 7,
          },
          375: {
            slidesPerView: 1.8,
            spaceBetween: 7,
          },
          390: {
            slidesPerView: 2,
            spaceBetween: 7,
          },
          550: {
            slidesPerView: 2,
            spaceBetween: 7,
          },
          660: {
            slidesPerView: 2.5,
            spaceBetween: 7,
          },
          790: {
            slidesPerView: 3,
            spaceBetween: 7,
          },
          850: {
            slidesPerView: 3.2,
            spaceBetween: 7,
          },
          960: {
            slidesPerView: 4,
            spaceBetween: 7,
          },
          1080: {
            slidesPerView: 4,
            spaceBetween: 18,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 18,
          },
        }}>
        {slidesList.map((slideContent, index) => (
          <SwiperSlide key={index} className="swiper-slide">
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledSlideContainer>
  );
};

export { StyledProductRecommendationSlider };

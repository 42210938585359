/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import { styled } from "@mui/system";
import CardMedia from "@mui/material/CardMedia";
import { dimensions } from "../../themes/variables";

const thumbnailSize = dimensions.productCard.thumbnail;

const StyledCardMedia = styled(CardMedia)`
  height: 270px;
  max-width: 100%;
  width: auto;
  max-height: inherit;
  object-fit: cover;

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export { StyledCardMedia };

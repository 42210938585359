/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { ChangeEvent, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OK } from "http-status-codes";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import getDisplayName from "react-display-name";
import cloneDeep from "lodash/cloneDeep";
//Foundation libraries
import { useSite } from "../../../_foundation/hooks/useSite";
import siteContentService from "../../../_foundation/apis/search/siteContent.service";
import searchDisplayService from "../../../_foundation/apis/transaction/searchDisplay.service";
//Custom libraries
import { CommerceEnvironment, KEY_CODES, SEARCHTERM } from "../../../constants/common";
import { SEARCH } from "../../../constants/routes";
import { KEYWORD_LIMIT } from "../../../configs/catalog";
//Redux
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import * as searchActions from "../../../redux/actions/search";
import { resetProductListAction } from "../../../redux/actions/catalog";
//UI
import {
  StyledTextField,
  StyledIconButton,
  StyledMenuItem,
  StyledSearchBar,
  StyledMenuTypography,
  StyledLink,
  StyledAvatar,
  StyledTypography,
  StyledGrid,
} from "@hcl-commerce-store-sdk/react-component";
import { InputAdornment, ClickAwayListener, Input, Divider, Hidden } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { commonUtil } from "@hcl-commerce-store-sdk/utils";
import storeUtil from "../../../utils/storeUtil";
import { SUGGESTIONS } from "../../../_foundation/constants/common";
import { debounce } from "lodash-es";
import { makeStyles } from "@mui/styles";

const SearchBar: React.FC<SearchBarProps> = ({ showSearchBar, openSearchBar, closeSearchBar }) => {
  const widget = useMemo(() => getDisplayName(SearchBar), []);
  const controller = useMemo(() => new AbortController(), []);
  const contractId = useSelector(currentContractIdSelector);
  const [keywordSuggestions, setKeywordSuggestions] = React.useState<Array<any>>([]);
  const [categorySuggestions, setCategorySuggestions] = React.useState<Array<any>>([]);
  const [brandSuggestions, setBrandSuggestions] = React.useState<Array<any>>([]);
  const [sellerSuggestions, setSellerSuggestions] = React.useState<Array<any>>([]);
  const [productSuggestions, setProductSuggestions] = React.useState<Array<any>>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location: any = useLocation();

  const searchField = t("SearchBar.SearchField");
  const keywordTitle = t("SearchBar.KeywordTitle");
  const categoryTitle = t("SearchBar.CategoryTitle");
  const brandTitle = t("SearchBar.BrandTitle");
  const sellerTitle = t("SearchBar.SellerTitle");
  const productTitle = t("SearchBar.ProductsTitle");
  const [input, setInput] = React.useState("");
  const [nameList, setNameList] = React.useState<Array<string>>([]);
  const [index, setIndex] = React.useState(0);
  const { mySite } = useSite();
  const dispatch = useDispatch();
  const [showKeywords, setShowKeywords] = React.useState(false);
  const [showCategories, setShowCategories] = React.useState(false);
  const [showBrands, setShowBrands] = React.useState(false);
  const [showSellers, setShowSellers] = React.useState(false);
  const [showProducts, setShowProducts] = React.useState(false);

  const getSuggestions = useCallback(
    (name, index, url?, thumbnail?, isManufacturer = false) => ({
      ...cloneDeep(CommerceEnvironment.suggestionSkeleton),
      name,
      arrIndex: `${index}`,
      url: url ?? `${SEARCH}?${SEARCHTERM}=${commonUtil.encodeURLParts(name)}&manufacturer=${isManufacturer}`,
      thumbnail,
    }),
    []
  );

  const clearSuggestions = useCallback(() => {
    setIndex(0);
    setKeywordSuggestions([]);
    setCategorySuggestions([]);
    setBrandSuggestions([]);
    setSellerSuggestions([]);
    setProductSuggestions([]);
    setShowKeywords(false);
    setShowCategories(false);
    setShowBrands(false);
    setShowSellers(false);
  }, []);

  const clearSuggestionsAndUpdateInputField = (str: string) => {
    clearSuggestions();
    str = callRegex(str);
    setInput(str);
    setShowSearchBar(!showSearchBar);
    dispatch(resetProductListAction());
  };

  const clearSuggestionsAndInputField = (prev) => {
    clearKeywords();
    clearSuggestions();
    setInput("");
    setShowSearchBar(!prev);
  };

  const clearKeywords = useCallback(() => dispatch(searchActions.KEYWORDS_RESET_ACTION("")), []); // eslint-disable-line react-hooks/exhaustive-deps
  const payloadBase = useMemo(() => ({ widget, signal: controller.signal }), []); // eslint-disable-line react-hooks/exhaustive-deps
  const setKeywordsToLocalStorage = useCallback(
    (list: string[]) => dispatch(searchActions.KEYWORDS_UPDATED_ACTION(list)),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const searchTermValue = params.get(SEARCHTERM);
    if (searchTermValue === null) {
      setInput("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleLookAheadSearch = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    event.persist();
    const term = event.currentTarget.value ?? "";
    setInput(term);
    if (term.trim() === "") {
      clearSuggestions(); // Limpia las sugerencias si el término de búsqueda está vacío
    } else {
      retrieveSuggestions(term.trim());
    }
  };

  const controllerRef = React.useRef<AbortController | null>(null);

  const createNewController = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();
    return controllerRef.current.signal;
  };

  const generateSuggestions = useCallback(
    (suggestions: any[], userInput: string) => {
      let nameListIndex = 1;
      const regex = new RegExp(userInput, "ig");

      const root = suggestions ?? [];
      const asMap = storeUtil.toMap(root, "identifier");

      const keywordSuggestions = asMap[SUGGESTIONS.Keyword]?.entry ?? [];
      const categorySuggestions = asMap[SUGGESTIONS.Category]?.entry ?? [];
      const brandSuggestions = asMap[SUGGESTIONS.Brand]?.entry?.map((brand) => brand.name) ?? [];
      const sellerSuggestions = asMap[SUGGESTIONS.Seller]?.entry?.map((seller) => seller.name) ?? [];
      const productSuggestion = asMap[SUGGESTIONS.Product]?.entry ?? [];

      const matchedKeywords = keywordSuggestions.map(({ term }) => getSuggestions(term, nameListIndex++));

      const matchedCategories = categorySuggestions
        .filter(({ fullPath }) => fullPath.match(regex))
        .slice(0, 4)
        .map(({ fullPath, seo }) => getSuggestions(fullPath, nameListIndex++, seo?.href));

      const matchedBrands = brandSuggestions
        .filter((e) => e.match(regex))
        .slice(0, 4)
        .map((brand) => getSuggestions(brand, nameListIndex++, null, null, true));

      const matchedSellers = sellerSuggestions
        .filter((e) => e.match(regex))
        .slice(0, 4)
        .map((seller) => getSuggestions(seller, nameListIndex++));

      const matchedProducts = productSuggestion.slice(0, 4).map(({ name, seo, thumbnail, price }) => ({
        ...getSuggestions(name, nameListIndex++, seo?.href, thumbnail),
        price,
      }));

      /* console.log("matchedProducts", matchedProducts); */
      setKeywordSuggestions(matchedKeywords);
      setCategorySuggestions(matchedCategories);
      setBrandSuggestions(matchedBrands);
      setSellerSuggestions(matchedSellers);
      setProductSuggestions(matchedProducts);

      setShowKeywords(true);
      setShowCategories(true);
      setShowBrands(true);
      if (sellerSuggestions.length) {
        setShowSellers(true);
      }

      const terms = matchedKeywords.map(({ name }) => name);
      setKeywordsToLocalStorage(terms);
      setShowProducts(true);

      return [
        userInput,
        ...terms,
        ...[matchedCategories, matchedBrands, matchedSellers, matchedProducts].flat(1).map(({ name }) => name),
      ];
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const retrieveSuggestions = useMemo(
    () =>
      debounce(async (searchTerm: string) => {
        if (searchTerm.length > 1) {
          const storeID = mySite.storeID;
          const catalogId = mySite.catalogID;

          const parameters: any = {
            responseFormat: "application/json",
            storeId: storeID,
            suggestType: SUGGESTIONS.All,
            limit: KEYWORD_LIMIT,
            contractId,
            catalogId,
            query: { searchTerm },
            signal: createNewController(),
          };

          clearKeywords();
          clearSuggestions();
          try {
            const res = await siteContentService.findSuggestionsUsingGET(parameters);
            if (res.status === OK) {
              const list = generateSuggestions(res?.data?.suggestionView, searchTerm);
              setNameList(list);
            }
          } catch (error: any) {
            if (error.name !== "AbortError") {
              console.error("Error in findSuggestionsUsingGET API call", error);
            }
          }
        }
      }, 500),
    [contractId, generateSuggestions, mySite]
  );

  useEffect(() => {
    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, []);

  /*
  const retrieveSuggestions = useMemo(
    () =>
      debounce(async (searchTerm: string) => {
        if (searchTerm.length > 1) {
          const storeID = mySite.storeID;
          const catalogId = mySite.catalogID;

          const parameters: any = {
            responseFormat: "application/json",
            storeId: storeID,
            suggestType: SUGGESTIONS.All,
            limit: KEYWORD_LIMIT,
            contractId,
            catalogId,
            query: { searchTerm },
            ...payloadBase,
          };

          clearKeywords();
          clearSuggestions();
          const res = await siteContentService.findSuggestionsUsingGET(parameters);
          if (res.status === OK) {
            const list = generateSuggestions(res?.data?.suggestionView, searchTerm);
            setNameList(list);
          }
        }
      }, 500),
    [contractId, generateSuggestions, mySite] // eslint-disable-line react-hooks/exhaustive-deps
  ); */

  const callRegex = (str: string) => {
    const regex2 = new RegExp(">", "ig");
    let arr: string[];
    if (str.match(regex2)) {
      arr = str.split(">");
      str = arr[arr.length - 1].trim();
    }
    return str;
  };
  const onKeyDown = (e) => {
    const len = nameList ? nameList.length : 0;
    let str = "";
    if (e.keyCode === KEY_CODES.UP) {
      e.preventDefault();

      if (index === 0) {
        return;
      }
      setIndex(index - 1);
      if (nameList) {
        str = callRegex(nameList[index - 1]);
        setInput(str);
      }
    } else if (e.keyCode === KEY_CODES.DOWN) {
      e.preventDefault();

      if (index === len - 1) {
        setIndex(0);
        if (nameList) {
          str = callRegex(nameList[0]);
          setInput(str);
        }
        return;
      }
      setIndex(index + 1);
      if (nameList) {
        str = callRegex(nameList[index + 1]);
        setInput(str);
      }
    }
  };

  const submitSearch = (props: any) => {
    props.preventDefault();
    clearSuggestions();

    if (input?.trim()) {
      let url = "";
      const storeID = mySite.storeID;
      const searchTerm = input.trim();
      const parameters: any = { storeId: storeID, searchTerm, ...payloadBase };
      searchDisplayService
        .getSearchDisplayView(parameters)
        .then((res) => {
          if (res.status === OK) {
            dispatch(resetProductListAction());
            url = res?.data.redirecturl;

            if (url === undefined) {
              url = SEARCH + "?" + SEARCHTERM + "=" + searchTerm;
            }
            redirectTo(url);
          }
        })
        .catch((e) => {
          console.log("Error in getSearchDisplayView API call", e);
        });
    }
  };

  const redirectTo = (url: string) => {
    clearSuggestions();
    setShowSearchBar(false);
    //redirect
    if (url.startsWith("http")) {
      window.location.href = url;
    } else {
      navigate(url);
    }
  };

  const clickAway = (prev) => {
    setShowSearchBar(!prev);
  };

  const setShowSearchBar = (boolean) => {
    if (boolean) {
      openSearchBar();
    } else {
      closeSearchBar();
    }
  };

  const calculateDiscountPercentage = (originalPrice, discountedPrice) => {
    const diff = originalPrice - discountedPrice;
    return Math.round((diff / originalPrice) * 100);
  };

  const toggleSearchBar = () => setShowSearchBar(!showSearchBar);

  useEffect(() => () => controller.abort(), []); // eslint-disable-line react-hooks/exhaustive-deps

  const useStyles = makeStyles((theme) => ({
    searchResults: {
      position: "absolute",
      left: 0,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],

      padding: "0.5rem",
      marginTop: "1rem",
      marginBottom: "0.5rem",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        overflowY: "auto",
      },
      "@media (max-width: 959px)": {
        marginTop: "0.5rem",
        paddingBottom: "1rem",
      },
      "&::-webkit-scrollbar": {
        width: "0.5rem",
      },
      "&::-webkit-scrollbar-thumb": {},
      "& .MuiInputBase": {
        color: "red",
      },
    },
    titles: {
      marginTop: "2rem",
      color: "#797979",
      font: "normal normal 600 13px/15px Rubik",
      "&:hover": {
        color: "#797979",
      },
      "@media (max-width: 959px)": {
        marginTop: "0.5rem",
      },
    },
    titleDestacados: {
      marginTop: "20px",
      marginLeft: "5rem",
      color: "#797979",
      font: "normal normal 600 13px/15px Rubik",
      "&:hover": {
        color: "#797979",
      },
      "@media (max-width: 1080px)": {
        marginLeft: "2.5rem",
      },
      "@media (max-width: 959px)": {
        marginTop: "0.5rem",
      },
    },
    inputStyles: {
      backgroundColor: "#585858",
      border: "none",
      "& input": {
        fontSize: "16px",
      },
      "&::placeholder": {
        color: "white",
      },
      "& .MuiInputBase-input": {
        color: "white",
      },
    },
    searchIcon: {
      fontSize: "34px",
      color: "white",
    },
    mainProductosContainer: {
      flexBasis: "70%",
      maxWidth: "70%",
    },

    productsContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      margin: "0.2rem",
      /*       flexDirection: "row", */
      marginRight: "2rem",
      marginBottom: "2rem",
      marginLeft: "4.5rem",
      padding: "0.5rem",
      /* margin: "0", */
      "@media (max-width: 1080px)": {
        marginLeft: "2rem",
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        overflowY: "auto",
      },
      "& > a": {
        display: "block",
        textDecoration: "none",
        color: "inherit",
        width: "22%",
        margin: "5px 0 10px 0",
        "&:hover": {
          backgroundColor: "transparent",
        },
        "& > li": {
          listStyle: "none",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 0,
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    imagesContainer: {
      maxWidth: "100%",
      /* maxHeight: "300px", */
      height: "auto",
      width: "100%",
      objectFit: "cover",
      borderRadius: "0",
      /*       [theme.breakpoints.down("sm")]: {
        maxHeight: "50%",
        maxWidht: "50%",
      }, */
      " & img": {
        maxHeight: "280px",
        height: "16vw",
      },
    },
    linkStyle: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    listItemStyle: {
      color: "#272633",
      marginTop: "1.5rem",
      textTransform: "capitalize",
      font: "normal normal 15px/18px Rubik",
      fontWeight: "600",
      "&:hover": {
        color: "#533278",
        textDecoration: "underline",
        backgroundColor: "transparent",
      },
    },
    keywordsContainer: {
      flexBasis: "20%",
      maxWidth: "20%",
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      width: "100%",
      marginLeft: "60px",
      overflow: "hidden",
      "& > a": {
        "&:hover": {
          backgroundColor: "transparent",
        },
        "& > li": {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
        "& > p": {},
      },
      "@media (max-width: 959px)": {
        marginBottom: "0.5rem",
        marginLeft: "15px",
        flexBasis: "100%",
        maxWidth: "100%",
      },
      "@media (max-width: 480px)": {
        marginBottom: "0rem",
      },
    },
    iconoHover: {
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },
  }));

  const classes = useStyles();

  return (
    <StyledGrid>
      <StyledGrid style={{ position: "relative", width: "100%" }}>
        <Hidden mdUp>
          <StyledSearchBar>
            <form onSubmit={submitSearch} noValidate>
              <StyledGrid container alignItems="center" spacing={2} style={{ marginTop: "0" }}>
                {/* TextField ocupando el espacio disponible */}
                <StyledGrid item style={{ flexGrow: 1 }}>
                  <StyledTextField
                    fullWidth
                    margin="normal"
                    size="small"
                    autoFocus
                    autoComplete="off"
                    type="text"
                    placeholder={searchField}
                    value={input}
                    name="searchTerm"
                    onChange={handleLookAheadSearch}
                    onKeyDown={onKeyDown}
                    InputProps={{
                      className: classes.inputStyles,
                      endAdornment: (
                        <StyledIconButton className="horizontal-padding-1">
                          <SearchIcon className={classes.searchIcon} onClick={toggleSearchBar} />
                        </StyledIconButton>
                      ),
                    }}
                  />
                </StyledGrid>
                <StyledGrid item>
                  <StyledIconButton
                    data-testid="button-clear-search-suggestions-input-fields"
                    onClick={clearSuggestionsAndInputField}
                    style={{ padding: 0, minWidth: "auto" }}>
                    <StyledTypography titleAccess={t("SearchBar.Clear")}>Cancelar</StyledTypography>
                  </StyledIconButton>
                </StyledGrid>
              </StyledGrid>
            </form>
          </StyledSearchBar>
        </Hidden>

        <Hidden mdDown>
          <StyledSearchBar>
            <form onSubmit={submitSearch} noValidate>
              <StyledTextField
                margin="normal"
                size="small"
                autoFocus
                autoComplete="off"
                type="text"
                placeholder={searchField}
                style={{
                  width: "auto",
                }}
                value={input}
                name="searchTerm"
                onChange={handleLookAheadSearch}
                onKeyDown={onKeyDown}
                InputProps={{
                  className: classes.inputStyles,
                  endAdornment: (
                    // <>
                    //   {showKeywords || showCategories || showBrands || showSellers ? (
                    //     <InputAdornment position="end">
                    //       <StyledIconButton
                    //         data-testid="button-clear-search-suggestions-input-fields"
                    //         onClick={clearSuggestionsAndInputField}>
                    //         <CloseIcon titleAccess={t("SearchBar.Clear")} />
                    //       </StyledIconButton>
                    //     </InputAdornment>
                    //   ) : (
                    //     <InputAdornment position="start">
                    //       {/* <SearchIcon data-testid="icon-toggle-search-bar" onClick={toggleSearchBar} /> */}
                    //     </InputAdornment>
                    //   )}
                    // </>
                    <StyledIconButton className="horizontal-padding-1">
                      <SearchIcon
                        className={classes.searchIcon}
                        /* onClick={toggleSearchBar} */ onClick={submitSearch}
                      />
                    </StyledIconButton>
                  ),
                }}
              />
            </form>
          </StyledSearchBar>
        </Hidden>
      </StyledGrid>
      {showKeywords || showCategories ? (
        <ClickAwayListener onClickAway={clearSuggestionsAndInputField}>
          <StyledGrid container className={classes.searchResults}>
            <div
              style={{
                maxWidth: "1400px",
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                flexDirection: "row",
              }}>
              <Hidden mdDown>
                {showProducts ? (
                  <StyledGrid
                    /* item
                  md={8} */
                    className={classes.mainProductosContainer}
                    style={{ display: "flex", flexDirection: "column" }}>
                    <StyledMenuTypography variant="body2" className={classes.titleDestacados}>
                      {/* {productTitle} */} {/* PENDIENTE */}
                      PRODUCTOS DESTACADOS
                    </StyledMenuTypography>
                    <StyledGrid className={classes.productsContainer}>
                      {productSuggestions?.map((e: any, i: number) => (
                        <StyledLink
                          className={classes.linkStyle}
                          key={`product-${i}`}
                          testId={`product-${e.name.toLowerCase()}`}
                          to={e.url}
                          onClick={(evt) => clearSuggestionsAndUpdateInputField(e.name)}>
                          <StyledMenuItem
                            style={{ display: "flex", flexDirection: "column" }}
                            className={classes.listItemStyle}>
                            <div style={{ position: "relative" }}>
                              <StyledAvatar
                                style={{}}
                                alt={e.name}
                                src={e.thumbnail}
                                className={classes.imagesContainer}
                              />
                              {/* Condición para mostrar la etiqueta de descuento */}
                              {e.price.length > 1 && parseFloat(e.price[1].value) < parseFloat(e.price[0].value) && (
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "0",
                                    left: "0",
                                    backgroundColor: "#BF1C1C",
                                    color: "white",
                                    padding: "2px 5px",
                                    borderRadius: "2px",
                                    fontSize: "0.8em",
                                  }}>
                                  {calculateDiscountPercentage(
                                    parseFloat(e.price[0].value),
                                    parseFloat(e.price[1].value)
                                  )}
                                  %
                                </span>
                              )}
                            </div>
                            <StyledMenuTypography
                              className={e.arrIndex === index ? "active" : ""}
                              component="div"
                              key={e.arrIndex}
                              id={`megamenu_department_${e.id}`}
                              title={e.name}>
                              <StyledTypography
                                className="wrapText"
                                style={{
                                  font: "normal normal normal 14px/15px Rubik",
                                  color: "#272633",
                                  textAlign: "left",
                                  marginTop: ".5rem",
                                  marginBottom: "10px",
                                }}>
                                {e.name}
                              </StyledTypography>
                              <StyledTypography
                                className="wrapText"
                                style={{
                                  fontWeight: "bold",
                                  font: "normal normal 600 14px/17px Rubik",
                                  marginTop: "0.5rem",
                                }}>
                                {`$${e.price[0].value}` === `$${e.price[1].value}` ? (
                                  `$${e.price[0].value}`
                                ) : (
                                  <>
                                    <span> ${e.price[1].value}</span>
                                  </>
                                )}
                                {/* {e.price.length > 0 ? `$${e.price[0].value}` : "Precio no disponible"}
                            {e.price.length > 0 ? `$${e.price[1].value}` : "Precio no disponible"} */}
                              </StyledTypography>
                            </StyledMenuTypography>
                          </StyledMenuItem>
                        </StyledLink>
                      ))}
                    </StyledGrid>
                  </StyledGrid>
                ) : null}
              </Hidden>
              <Hidden mdDown>
                <Divider orientation="vertical" variant="middle" flexItem />
              </Hidden>
              {showKeywords ? (
                <StyledGrid /* item md={3} */ className={classes.keywordsContainer}>
                  <StyledMenuTypography variant="body2" className={classes.titles} style={{}}>
                    {/* {keywordTitle} */} {/* PENDIENTE */}
                    SUGERENCIAS PRINCIPALES
                  </StyledMenuTypography>
                  {keywordSuggestions?.map((e: any, i: number) => (
                    <StyledLink
                      key={`keyword-${i}`}
                      testId={`keywords-${e.name}`}
                      to={e.url}
                      onClick={() => clearSuggestionsAndUpdateInputField(e.name)}>
                      <StyledMenuTypography variant="body2" className={classes.listItemStyle} style={{}}>
                        {e.name}
                      </StyledMenuTypography>
                    </StyledLink>
                  ))}
                </StyledGrid>
              ) : null}
            </div>
          </StyledGrid>
        </ClickAwayListener>
      ) : null}
    </StyledGrid>
  );
};

interface SearchBarProps {
  showSearchBar: boolean;
  openSearchBar: any;
  closeSearchBar: any;
}

export { SearchBar };

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { StyledBox, StyledContainer } from "../../elements";
import MenubarMenuItem from "./MenubarMenuItem";
import { Category } from "_foundation/types/category";
import { makeStyles } from "@mui/styles";

interface ExpandedMenuProps {
  pages?: Category[];
}
const EMPTY_CATS = [] as Category[];

const headerStyles = makeStyles((theme) => ({
  headerContainer: {
    maxWidth: "100%",
  },
}));

const ExpandedMenu: React.FC<ExpandedMenuProps> = ({ pages = EMPTY_CATS }) => {
  const classesHeader = headerStyles();
  const { t } = useTranslation();
  const location: any = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState<any | null>(null);
  const categories = [...pages];

  const selectMenuItem = (cid: string) => {
    setSelectedMenuItem(categories.filter((c) => c.id === cid)[0] || null);
  };

  React.useEffect(() => {
    setSelectedMenuItem(null);
  }, [location]);

  return (
    <StyledBox className="expanded-menu-container">
      <StyledContainer overflow="hidden" className={classesHeader.headerContainer}>
        <StyledBox display="flex" flexWrap="nowrap" justifyContent="flex-start" alignContent="flex-start">
          {categories.map((page) => (
            <MenubarMenuItem
              key={page.id}
              selectedMenuItem={selectedMenuItem}
              selectMenuItem={selectMenuItem}
              page={page}
            />
          ))}
        </StyledBox>
      </StyledContainer>
    </StyledBox>
  );
};

export default ExpandedMenu;

// src/redux/actions/is-review.ts
export const SET_REVIEW_VIEW = "SET_REVIEW_VIEW";

// Define una interfaz para el tipo de acción
export interface SetReviewViewAction {
  type: typeof SET_REVIEW_VIEW;
  payload: boolean;
}

// Función creadora de acciones
export function setReviewView(isReviewView: boolean): SetReviewViewAction {
  return {
    type: SET_REVIEW_VIEW,
    payload: isReviewView,
  };
}

// Tipo de exportación para ser utilizado en el reducer
export type ReviewActions = SetReviewViewAction;

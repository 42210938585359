/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2022
 *
 *==================================================
 */
//Standard libraries
import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Axios, { Canceler } from "axios";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
//Custom libraries
import { CONSTANTS } from "../../constants/order-item-table";
import FormattedPriceDisplay from "../../components/widgets/formatted-price-display";
import { PAGINATION_CONFIGS } from "../../configs/order";
import * as ROUTES from "../../constants/routes";
//Redux
import { currentContractIdSelector } from "../../redux/selectors/contract";
import * as orderActions from "../../redux/actions/order";
import { forUserIdSelector, loginStatusSelector, userIdSelector } from "../../redux/selectors/user";
import { entitledOrgSelector, activeOrgSelector } from "../../redux/selectors/organization";
//UI
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TruckIcon from "@mui/icons-material/LocalShipping";

import {
  StyledAvatar,
  StyledGrid,
  StyledTypography,
  TableColumnDef,
  withCustomTableContext,
  CustomTable,
  useTableUtils,
  TableConstants,
  useCustomTable,
  StyledLink,
  StyledTooltip,
} from "@hcl-commerce-store-sdk/react-component";

//GA360
import AsyncCall from "../../_foundation/gtm/async.service";
import { cartSelector, orderMethodIsPickupSelector, orderItemsSelector } from "../../redux/selectors/order";
import { useWinDimsInEM } from "./use-win-dims-in-em";
import { STRING_TRUE, XS_MOBILE_W } from "../../constants/common";
import { get, isEmpty } from "lodash-es";
import storeUtil from "../../utils/storeUtil";
import inventoryavailabilityService from "../apis/transaction/inventoryavailability.service";
import { useStoreLocatorValue } from "../context/store-locator-context";
import { sellersSelector } from "../../redux/selectors/sellers";
import { makeStyles } from "@mui/styles";
import { useMediaQuery } from "@mui/material";
import { recurringOrderSelector } from "redux/selectors/recurringOrder";
import productsService from "../../_foundation/apis/search/products.service";

const DetailPanel = ({ rowData, ...props }) => {
  const { attributes: rawData } = rowData;
  const cellStyle = { verticalAlign: "middle" };
  const { t } = useTranslation();
  const attrs = rawData?.filter((a) => STRING_TRUE === a.displayable);
  console.log(attrs);

  // generate headers array
  const columns = attrs?.map((a, i) => ({
    title: a.name,
    idProp: "name",
    keyLookup: { key: `attr_${i}_value` },
    display: { cellStyle },
  }));

  // generate single row out of all attribute values
  const data = [
    attrs?.reduce((n, v, i) => {
      n[`attr_${i}_value`] = storeUtil.csValue(get(v, "values[0].value", t("CommerceEnvironment.inventoryStatus.NA")));
      return n;
    }, {}),
  ];
  const className = "detailPanel table-tablet";
  const style = { width: "auto", border: "0" };
  const D = useMemo(() => withCustomTableContext(CustomTable), []);
  return columns?.length > 0 ? (
    <D
      {...{
        t,
        data,
        columns,
        style,
        className,
        /* outerClassName: "order-item-table-drawer", */
        labels: { emptyMsg: "InprogressItems.noItems" },
      }}
    />
  ) : null;
};

function calculateAvailability(o: any, availability: Availability, counter: any) {
  const { onlineInventory, sellerInventory, physicalStoreInventory } = availability;
  const deliveryInventory = [...onlineInventory, ...sellerInventory];
  const { partNumber } = o;
  let count = counter[partNumber];
  try {
    const qty = parseInt(o.quantity);
    if (physicalStoreInventory.length > 0) {
      //pickup in store
      if (!count) {
        const _avl = physicalStoreInventory.find((i) => i.partNumber === partNumber);
        if (_avl?.availableQuantity) {
          count = parseInt(_avl.availableQuantity);
        }
      }
      if (!count || count < qty) {
        o["availability"] = "NOT_AVAIL_PICKUP";
      } else {
        count = count - qty;
        o["availability"] = "AVAIL_PICKUP";
      }
      counter[partNumber] = count;
    } else {
      if (!count) {
        const _avl = deliveryInventory.find((i) => i.partNumber === partNumber);
        if (_avl?.availableQuantity) {
          count = parseInt(_avl.availableQuantity);
        }
      }
      if (!count || count < qty) {
        o["availability"] = "NOT_AVAIL_DELIVERY";
      } else {
        count = count - qty;
        o["availability"] = "AVAIL_DELIVERY";
      }
      counter[partNumber] = count;
    }
  } catch (e) {
    console.log("fail to calculate availability", o, availability, e);
  }
}

type OrderItem = {
  partNumber: string;
  thumbnail?: string;
  name: string;
  orderItemStatus: string;
};

interface PartNumberMap {
  [key: string]: Set<string>;
}

interface Availability {
  onlineInventory: any[];
  physicalStoreInventory: any[];
  sellerInventory: any[];
}

/**
 * Order item table component
 * displays order item table with item info, inventory status, quantity and actions
 * allows for ready-only mode with no edits/actions
 * @param props
 */
export const useOrderItemTable = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mySite } = useSite();
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];
  const contractId = useSelector(currentContractIdSelector);
  const loginStatus = useSelector(loginStatusSelector);
  const isRecurringOrderFeatureEnabled = mySite?.isB2B && loginStatus;
  const { showDeleteButton, showQuantityCell, showPriceLabel, preShip, data: dataProps, cartPage, isCartView } = props;
  const { tableState, setTableState } = useCustomTable();
  const { setValueForCell } = useTableUtils();
  const [actionData, setActionData] = useState<any>(null);
  const sellers = useSelector(sellersSelector);
  const orderMethodIsPickup = useSelector(orderMethodIsPickupSelector);
  const reviewPage = useLocation().pathname === ROUTES.CHECKOUT + "/" + ROUTES.CHECKOUT_REVIEW;

  const isMobile = useMediaQuery("(max-width:960px)");
  const storeId: string = mySite ? mySite.storeID : "";
  const payloadBase: any = {
    storeId: storeId,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };
  const { storeLocator } = useStoreLocatorValue();
  const selectedStore = useMemo(() => storeLocator.selectedStore, [storeLocator]);
  const [availability, setAvailability] = useState<Availability>();
  const [timestamps, setTimestamps] = useState<Record<string, string>>({});

  const getInventory = async (pnMap: PartNumberMap) => {
    let physicalStoreInventory: any[] = [];
    let onlineInventory: any[] = [];
    let sellerInventory: any[] = [];
    if (orderMethodIsPickup && selectedStore) {
      const _pns: string[] = [];
      Object.values(pnMap).forEach((pn: Set<string>) => _pns.push(...Array.from(pn)));
      const _params = {
        ...payloadBase,
        partNumbers: _pns.join(),
        physicalStoreName: selectedStore.physicalStoreName,
      };
      try {
        const resp = await inventoryavailabilityService.getInventoryAvailabilityByPartNumber(_params);
        physicalStoreInventory = (resp.data.InventoryAvailability ?? []).filter((a) => a.physicalStoreId);
      } catch (e) {
        console.log("fail to get physical inventory", _params, e);
      }
    } else {
      const _ops: Promise<any>[] = [];
      const _sps: Promise<any>[] = [];
      for (const [key, _pn] of Object.entries(pnMap)) {
        if (key === "online") {
          //online inventory, only one sevice call.
          const _params = {
            ...payloadBase,
            partNumbers: Array.from(_pn).join(),
          };
          _ops.push(inventoryavailabilityService.getInventoryAvailabilityByPartNumber(_params));
        } else {
          //seller inventory call, grouped by sellerId
          const _params = {
            ...payloadBase,
            partNumbers: Array.from(_pn).join(),
            sellerId: key,
          };
          _sps.push(inventoryavailabilityService.getInventoryAvailabilityByPartNumber(_params));
        }
      }
      if (_ops.length > 0) {
        try {
          const _oresp = await _ops[0];
          onlineInventory = _oresp.data.InventoryAvailability ?? [];
        } catch (e) {
          console.log("fail to get online inventory", e);
        }
      }
      if (_sps.length > 0) {
        try {
          const _resps = await Promise.all(_sps);
          sellerInventory = (
            _resps.reduce((p, c) => {
              const _a: any[] = c.data.InventoryAvailability ?? [];
              p.push(..._a);
              return p;
            }, []) as any[]
          ).filter((a) => a.x_sellerId);
        } catch (e) {
          console.log("fail to get seller inventory", e);
        }
      }
    }

    setAvailability({
      onlineInventory,
      sellerInventory,
      physicalStoreInventory,
    });
  };

  /**
   * Initialize table data by making a copy
   * Material-table alters the input data, so data cannot be of immutable type
   * @returns Copy of the data prop
   */
  const data = useMemo(() => {
    /**
     * {partnumber: remainingInventory}
     * This is based on one partnumber only have one seller
     */
    const counter: any = {};
    const newData = (dataProps ?? []).map((oi) => {
      const _oi = { ...oi };
      if (availability) {
        calculateAvailability(_oi, availability, counter);
      }
      return _oi;
    });
    return newData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProps, availability]);

  const readOnly = props.readOnly !== undefined ? props.readOnly : true;
  const miniCartView = props.miniCartView !== undefined ? props.miniCartView : false;

  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : "";
  //update this flag as need later
  const pagination = !readOnly && !miniCartView;
  const handleMiniCartClose = props.handleMiniCartClose !== undefined ? props.handleMiniCartClose : null;
  const { seller } = props;
  /**
   * Initialize quantity data per order item
   * @returns quantities object for each order item
   */

  const initQuantityData = () => {
    const newData: any = {};
    const pnMap: PartNumberMap = {};
    if (dataProps) {
      // get all inventories
      dataProps.forEach((oi) => {
        const { partNumber, fulfillmentCenterOwnerId } = oi;
        if (sellers?.sellers?.some((s) => s.id === fulfillmentCenterOwnerId)) {
          let pSet: Set<string> = pnMap[fulfillmentCenterOwnerId];
          if (!pSet) {
            pSet = new Set<string>();
            pnMap[fulfillmentCenterOwnerId] = pSet;
          }
          pSet.add(partNumber);
        } else {
          let pSet: Set<string> = pnMap["online"];
          if (!pSet) {
            pSet = new Set<string>();
            pnMap["online"] = pSet;
          }
          pSet.add(partNumber);
        }
        if (oi.quantity) {
          try {
            const parsedQty = parseInt(oi.quantity);
            if (parsedQty > 0) {
              newData[oi.orderItemId] = parsedQty;
            }
          } catch (e) {
            console.log("Could not parse quantity", e);
          }
        }
      });
    }
    getInventory(pnMap);
    return newData;
  };
  const [quantityList, setQuantityList] = useState<any>({});
  const defaultOptions = {
    toolbar: false,
    header: !miniCartView,
    paging: pagination,
    pageSize: PAGINATION_CONFIGS.pageLimit,
    pageSizeOptions: PAGINATION_CONFIGS.pageSizeOptions,
    actionsColumnIndex: -1,
    fixedColumns: {
      left: 0,
      right: 0,
    },
  };
  const options = props.options !== undefined ? props.options : defaultOptions;

  const columns = useMemo(
    () => {
      const cancels: Canceler[] = [];
      const payloadBase: any = {
        currency: defaultCurrencyID,
        contractId: contractId,
        widget: "Order Item Table",
        cancelToken: new CancelToken(function executor(c) {
          cancels.push(c);
        }),
      };
      const itemValCalc = ({ rowData: r }) => r.name || r.partNumber;
      const oaValCalc = ({ rowData }) => {
        return storeUtil.constructInventoryMessage(rowData, t, cartPage, selectedStore?.storeName);
      };
      const priceCalc = ({ rowData: r }) => Number(r.orderItemPrice);
      const quantityCalc = ({ rowData: r }) => Number(quantityList[r.orderItemId]);
      const statusCalc = ({ rowData: r }) => t(`Order.Status_${r.orderItemStatus}`);

      const QuantityDisplay = (props: any) => (
        <StyledTypography data-testid={`order-item-quantity-${props.rowData.partNumber}`}>
          {quantityList[props.rowData.orderItemId]}
        </StyledTypography>
      );

      const ThumbnailCell = ({ rowData, ...props }) => {
        const CCStyles = makeStyles((theme) => ({
          quantityText: {
            position: "absolute",
            top: "-4px",
            right: "-8px",
            zIndex: "10",
            borderRadius: "50%",
            backgroundColor: "#000000",
            color: "white",
            padding: "1px 6px",
            textAlign: "right",
            font: "normal normal normal 14px/17px Rubik",
          },
          avatarContainer: {
            position: "relative",
            width: miniCartView ? "" : "100%",
            height: miniCartView ? "" : "100%",
          },
        }));

        const classesCart = CCStyles();

        return (
          <>
            {rowData.seo && rowData.seo.href ? (
              <StyledLink to={rowData.seo?.href} onClick={handleMiniCartClose ? handleMiniCartClose : null}>
                <div className={classesCart.avatarContainer}>
                  {miniCartView && (
                    <StyledTypography className={classesCart.quantityText}>
                      {Math.floor(rowData.quantity)}
                    </StyledTypography>
                  )}

                  <StyledAvatar
                    data-testid={`order-item-thumbnail-image-${rowData.partNumber}`}
                    alt={rowData.name}
                    src={rowData.thumbnail}
                    style={{
                      width: miniCartView ? "" : "100%",
                      height: miniCartView ? "" : "100%",
                      margin: "0px",
                      padding: miniCartView ? "0px" : "0px",
                    }}
                  />
                </div>
              </StyledLink>
            ) : (
              <StyledAvatar alt={rowData.name} src={rowData.thumbnail} />
            )}
          </>
        );
      };

      const ItemDetailsCell = ({ rowData, ...props }) => {
        const sizeAttribute = rowData.attributes?.find((attr) => attr.identifier === "Size");
        const sizeValue = sizeAttribute ? sizeAttribute.values[0] : "Unknown";

        /* Cart Content Styles */
        const CCStyles = makeStyles((theme) => ({
          styledLink: {},
          editText: {
            textDecorationLine: "underline",
            cursor: "pointer",
            fontSize: "0.925rem",
            "& a": { fontSize: "0.925rem !important" },
            "&:hover": {
              fontSize: "0.925rem !important",
              color: "#5C2896 !important",
            },
            marginTop: miniCartView ? "" : "8px",
          },
          textStyle: {
            font: "normal normal bold 17px/20px Rubik",
            color: !showPriceLabel ? "#272633" : "#272633",
            marginBottom: miniCartView ? "" : "8px",
            flex: "1",
            "&:hover": {
              color: "#5C2896 !important",
              font: "normal normal bold 17px/20px Rubik !important",
              textDecoration: "none !important",
            },
            "& a": {
              font: "normal normal bold 17px/20px Rubik !important",
              textDecoration: "none !important",
              "&:hover": {
                color: "#5C2896 !important",
              },
              "@media screen and (max-width: 40em)": {
                font: "normal normal bold 15px/15px Rubik !important",
              },
            },
          },
          sizeStyle: {
            font: "normal normal normal 17px/20px Rubik",
            color: "#818181",
            marginTop: miniCartView ? "8px" : "0px",
            marginBottom: miniCartView ? "0px" : "8px",
            whiteSpace: "normal",
            "@media screen and (max-width: 40em)": {
              font: "normal normal normal 15px/15px Rubik !important",
              color: "#818181",
            },
          },
          dataCell: {
            padding: miniCartView ? "" : "5px",
            paddingLeft: "10px",
            paddingTop: "0px",
            maxWidth: "100%",
            "@media screen and (max-width: 40em)": {
              paddingLeft: "8px",
              maxWidth: "95%",
            },
          },
          priceText: {
            font: "normal normal normal 17px/20px Rubik",
            letterSpacing: "0px",
            color: "#4B4B4B",
            marginTop: "8px",
            wordBreak: "normal",
            whiteSpace: "normal",
            "@media screen and (max-width: 40em)": {
              font: "normal normal normal 14px/18px Rubik",
            },
          },
        }));
        const classesCart = CCStyles();
        return (
          <>
            <StyledGrid wrap={miniCartView ? "" : undefined}>
              <StyledGrid item className={classesCart.dataCell}>
                <StyledTypography variant="body2" className={classesCart.textStyle}>
                  {rowData.seo && rowData.seo.href ? (
                    <StyledLink to={rowData.seo?.href} onClick={handleMiniCartClose} className={classesCart.styledLink}>
                      {rowData.name ? rowData.name : rowData.partNumber}
                    </StyledLink>
                  ) : rowData.name ? (
                    rowData.name
                  ) : (
                    rowData.partNumber
                  )}
                </StyledTypography>
                {sizeValue.value ? (
                  <StyledTypography
                    data-testid={`order-item-sku-${rowData.partNumber}`}
                    variant="body1"
                    className={classesCart.sizeStyle}>
                    {t("OrderItemTable.Labels.Size")}
                    {` ${sizeValue.value}`}
                  </StyledTypography>
                ) : null}
                {rowData.freeGift === "true" && (
                  <StyledTypography variant="overline" color="textSecondary" className={classesCart.sizeStyle}>
                    {t("OrderItemTable.Labels.Gift")}
                  </StyledTypography>
                )}
                {!miniCartView && isRecurringOrderFeatureEnabled && rowData.disallowRecurringOrder === "1" && (
                  <StyledTypography variant="overline" color="textSecondary" className={classesCart.sizeStyle}>
                    {t("OrderItemTable.Labels.NonRecurring")}
                  </StyledTypography>
                )}

                <>
                  {miniCartView && <OrderItemPrice rowData={rowData} miniCartView={miniCartView} />}
                  {showPriceLabel ? (
                    <StyledTypography style={{ whiteSpace: "normal" }}>
                      {" "}
                      <StyledTypography
                        data-testid={`order-item-price-${rowData.partNumber}`}
                        className={classesCart.priceText}>
                        <FormattedPriceDisplay min={parseFloat(rowData.unitPrice)} currency={rowData.currency} />
                      </StyledTypography>
                      {!miniCartView && null}
                    </StyledTypography>
                  ) : null}
                </>
              </StyledGrid>
            </StyledGrid>
            {/*             {miniCartView && (
              <StyledGrid item>
                <DeleteActionCell {...{ rowData }} />
              </StyledGrid>
            )} */}
          </>
        );
      };

      const QuantityCell = ({ rowData, ...props }) => {
        const forUserId = useSelector(forUserIdSelector);
        const uId = useSelector(userIdSelector);
        const userId = forUserId ?? uId;
        const cart = useSelector(cartSelector);
        const itemMemberId = rowData.xitem_memberId;
        const disabled =
          rowData.freeGift === "true" || (cart?.buyerId !== userId && userId !== itemMemberId) || reviewPage;

        /**
         * Dispatch quantity update action for order item
         * @param item The selected order item
         */

        const useStyles = makeStyles((theme) => ({
          button: {
            flex: 1,
            height: "3em",
            width: theme.spacing(5),
            fontSize: "1em",
            color: "#272633",
            border: "none !important",
            backgroundColor: "#E7EFFD",
            borderRadius: "0px !important",
            cursor: "pointer",
            "@media screen and (max-width: 40em)": {
              height: "2.3em",
              width: "35px",
            },
            "&:hover": {
              backgroundColor: "#cee0fb !important",
            },
            "&:active": {
              backgroundColor: "#cee0fb !important",
            },
            "&:focus": {
              backgroundColor: "#cee0fb !important",
              color: "#272633 !important",
            },
            "&:disabled": {
              backgroundColor: "#E7EFFD !important",
              color: "#272633 !important",
              cursor: "inherit",
            },
          },
          input: {
            textAlign: "center",
            border: "none",
            width: theme.spacing(5),
            fontSize: "1rem",
          },
          mainContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            height: "100%",
            paddingRight: "15px",
            "@media screen and (max-width: 40em)": {
              flexDirection: "row",
              paddingBottom: "5px",
            },
          },
          container: {
            display: "flex",
            border: "1px solid #CBCBCB",
            alignItems: "center",
            width: "auto",
          },
          deleteContainer: {
            display: "flex",
            alignItems: "center",
          },
        }));

        const QuantityInputWithButtons = ({ value, onChange, min, max, step, disabled }) => {
          const classes = useStyles();

          const handleDecrement = () => {
            if (value - step >= min) {
              onChange(value - step);
            }
          };

          const handleIncrement = () => {
            if (value + step <= max) {
              onChange(value + step);
            }
          };

          return (
            <StyledGrid className={classes.mainContainer}>
              <StyledGrid className={classes.container}>
                <button className={classes.button} onClick={handleDecrement} disabled={disabled || value <= min}>
                  -
                </button>
                <input
                  className={classes.input}
                  type="text"
                  value={value}
                  readOnly
                  min={min}
                  max={max}
                  step={step}
                  disabled={disabled}
                />
                <button className={classes.button} onClick={handleIncrement} disabled={disabled || value >= max}>
                  +
                </button>
              </StyledGrid>
            </StyledGrid>
          );
        };

        const onQuantityUpdate = (quantityString: string, item: any) => {
          if (item) {
            try {
              const quantity = parseInt(quantityString);
              if (quantity > 0) {
                const payload = {
                  ...payloadBase,
                  quantity: quantity.toString(),
                  orderItemId: item.orderItemId,
                  fetchCatentries: true,
                };
                dispatch(orderActions.UPDATE_ITEM_ACTION(payload));

                // update this (even tho it's temporary) just so that once the dispatch
                //   action completes, we don't "temporarily" revert back to the old value
                quantityList[rowData.orderItemId] = quantity;
              }
            } catch (e) {
              console.log("Could not parse quantity");
            }
          }
        };
        return !miniCartView && readOnly ? (
          <QuantityDisplay rowData={rowData} />
        ) : (
          <QuantityInputWithButtons
            value={quantityList[rowData.orderItemId]}
            onChange={(newValue) => onQuantityUpdate(newValue.toString(), rowData)}
            min={1}
            max={999}
            step={1}
            disabled={disabled}
          />
        );
      };

      const OrderItemPrice = ({ rowData, miniCartView }) => {
        const { t } = useTranslation();
        const dispatch = useDispatch();

        const MCCStyles = makeStyles(() => ({
          priceText: {
            font: "normal normal normal 17px/20px Rubik",
            letterSpacing: "0px",
            color: "#4B4B4B",
            marginTop: miniCartView ? "8px" : "0px",
            wordBreak: "normal",
            "@media screen and (max-width: 40em)": {
              font: "normal normal normal 14px/18px Rubik",
            },
          },
          deleteText: {
            textDecorationLine: "underline",
            cursor: "pointer",
            marginTop: "8px",
            "&:hover": {
              color: "gray",
            },
          },
        }));

        const classesItemPrice = MCCStyles();
        const [seoData, setSeoData] = useState<
          Map<
            string,
            {
              seo: string;
              thumbnail: string;
              price: { usage: string; description: string; currency: string; value: string }[];
            }
          >
        >(new Map());

        const getSEO = async (
          orderItems: OrderItem[],
          contractId: string
        ): Promise<
          Map<
            string,
            {
              seo: string;
              thumbnail: string;
              price: { usage: string; description: string; currency: string; value: string }[];
            }
          >
        > => {
          const seoUrlTemp = new Map<
            string,
            {
              seo: string;
              thumbnail: string;
              price: { usage: string; description: string; currency: string; value: string }[];
            }
          >();
          if (orderItems.length > 0) {
            const productsPartNumber = orderItems.map((element) => element.partNumber);

            const parameters = {
              partNumber: productsPartNumber,
              contractId: contractId,
            };

            try {
              const res = await productsService.findProductsUsingGET(parameters);

              if (res?.data?.contents?.length > 0) {
                res.data.contents.forEach((product) => {
                  seoUrlTemp.set(product.partNumber, {
                    seo: product.seo?.href,
                    thumbnail: product.fullImage,
                    price: product.price,
                  });
                });
              }
              return seoUrlTemp;
            } catch (e) {
              console.log("could not retrieve the parent product details", e);
              return seoUrlTemp;
            }
          }
          return seoUrlTemp;
        };

        useEffect(() => {
          const fetchSEOData = async () => {
            const data = await getSEO(dataProps, contractId);
            setSeoData(data);
          };

          fetchSEOData();
        }, [dataProps, contractId]);

        const removeItem = (item) => {
          const payload = {
            storeId: mySite.storeID,
            orderItemId: item.orderItemId,
            fetchCatentries: true,
          };
          dispatch(orderActions.REMOVE_ITEM_ACTION(payload));
        };
        return (
          <>
            {miniCartView ? (
              <>
                <StyledTypography
                  data-testid={`order-item-price-${rowData.partNumber}`}
                  className={classesItemPrice.priceText}>
                  <FormattedPriceDisplay min={parseFloat(rowData.orderItemPrice)} currency={rowData.currency} />
                </StyledTypography>
                {showDeleteButton ? (
                  <>
                    {" "}
                    <StyledTypography
                      color="primary"
                      onClick={() => removeItem(rowData)}
                      data-testid={`order-remove-item-button-${rowData.partNumber}`}>
                      <StyledTooltip title={t("CheckoutProfile.Delete")}>
                        <StyledTypography className={classesItemPrice.deleteText}>
                          {t("CheckoutProfile.Delete")}
                        </StyledTypography>
                      </StyledTooltip>
                    </StyledTypography>{" "}
                  </>
                ) : null}
              </>
            ) : (
              <>
                {isCartView && isMobile ? null : (
                  <StyledTypography
                    data-testid={`order-item-price-${rowData.partNumber}`}
                    align="inherit"
                    className={classesItemPrice.priceText}>
                    <FormattedPriceDisplay min={parseFloat(rowData.orderItemPrice)} currency={rowData.currency} />
                  </StyledTypography>
                )}
              </>
            )}
          </>
        );
      };

      const DeleteActionCell = ({ rowData, ...props }) => {
        const cart = useSelector(cartSelector);
        const forUserId = useSelector(forUserIdSelector);
        const uId = useSelector(userIdSelector);
        const entitledOrgs = useSelector(entitledOrgSelector);
        const activeOrgId = useSelector(activeOrgSelector);
        const sellers = useSelector(sellersSelector);
        const { t } = useTranslation();
        const userId = forUserId ?? uId;
        const itemMemberId = rowData.xitem_memberId;
        const [clicked, setClicked] = useState<boolean>(false);
        const disabled =
          clicked || rowData.freeGift === "true" || (cart?.buyerId !== userId && userId !== itemMemberId);

        /**
         * Dispatch action to remove selected order item
         * @param item The selected order item
         */
        const removeItem = (item: any) => {
          const orderItemId = item.orderItemId;
          const payload = {
            ...payloadBase,

            orderItemId: orderItemId,
            fetchCatentries: true,
          };
          setClicked(true);
          dispatch(orderActions.REMOVE_ITEM_ACTION(payload));

          //GA360
          if (mySite.enableGA) {
            const storeName = mySite.storeName;
            AsyncCall.sendRemoveFromCartEvent(item, entitledOrgs, activeOrgId, sellers, storeName, {
              enableUA: mySite.enableUA,
              enableGA4: mySite.enableGA4,
            });
          }
        };

        const MCCStyles = makeStyles((theme) => ({
          deleteText: {
            textDecorationLine: "underline",
            "&:hover": {
              cursor: "pointer",
              color: "gray",
            },
            "@media screen and (max-width: 960px)": {
              marginRight: "5px",
            },
          },
          deleteIcon: {
            cursor: "pointer",
          },
        }));

        const classesMiniCart = MCCStyles();

        return (
          <StyledTypography
            disabled={disabled}
            color="primary"
            onClick={() => removeItem(rowData)}
            data-testid={`order-remove-item-button-${rowData.partNumber}`}>
            <StyledTooltip title={t("CheckoutProfile.Delete")}>
              {miniCartView ? (
                <>
                  {!isMobile && (
                    <StyledTypography className={classesMiniCart.deleteText}>
                      {t("CheckoutProfile.Delete")}
                    </StyledTypography>
                  )}
                </>
              ) : (
                <DeleteOutlineIcon className={classesMiniCart.deleteIcon} />
              )}
            </StyledTooltip>
          </StyledTypography>
        );
      };

      /*  const ItemStatusCell = ({ rowData: r }) => {
        return t(`Order.Status_${r.orderItemStatus}`);
      }; */

      const CombinedCell = ({ rowData, ...props }) => {
        const forUserId = useSelector(forUserIdSelector);
        const uId = useSelector(userIdSelector);
        const userId = forUserId ?? uId;
        const cart = useSelector(cartSelector);
        const itemMemberId = rowData.xitem_memberId;
        const entitledOrgs = useSelector(entitledOrgSelector);
        const activeOrgId = useSelector(activeOrgSelector);
        const [clicked, setClicked] = useState<boolean>(false);
        const { w } = useWinDimsInEM();
        /* const mobile = !miniCartView && w > XS_MOBILE_W ? true : undefined; */
        const disabled =
          rowData.freeGift === "true" || (cart?.buyerId !== userId && userId !== itemMemberId) || reviewPage;

        /**
         * Dispatch quantity update action for order item
         * @param item The selected order item
         */

        const removeItem = (item: any) => {
          const orderItemId = item.orderItemId;
          const payload = {
            ...payloadBase,

            orderItemId: orderItemId,
            fetchCatentries: true,
          };
          setClicked(true);
          dispatch(orderActions.REMOVE_ITEM_ACTION(payload));

          //GA360
          if (mySite.enableGA) {
            const storeName = mySite.storeName;
            AsyncCall.sendRemoveFromCartEvent(item, entitledOrgs, activeOrgId, sellers, storeName, {
              enableUA: mySite.enableUA,
              enableGA4: mySite.enableGA4,
            });
          }
        };

        const useStyles = makeStyles((theme) => ({
          button: {
            flex: 1,
            height: "3em",
            width: theme.spacing(5),
            fontSize: "1em",
            color: "#272633",
            border: "none !important",
            backgroundColor: "#E7EFFD",
            borderRadius: "0px !important",
            "@media screen and (max-width: 40em)": {
              height: "2.3em",
              width: "35px",
            },
            "&:hover": {
              backgroundColor: "#cee0fb !important",
            },
            "&:active": {
              backgroundColor: "#cee0fb !important",
            },
            "&:focus": {
              backgroundColor: "#cee0fb !important",
              color: "#272633 !important",
            },
            "&:disabled": {
              backgroundColor: "#E7EFFD !important",
              color: "#272633 !important",
            },
          },
          input: {
            textAlign: "center",
            border: "none",
            width: theme.spacing(5),
            fontSize: "1rem",
          },
          mainContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "space-between",
            height: "100%",
            "@media screen and (max-width: 40em)": {
              paddingRight: "0px",
            },
          },
          container: {
            display: "flex",
            border: "1px solid #CBCBCB",
            alignItems: "center",
            width: "auto",
          },
          deleteContainer: {
            display: "flex",
            alignItems: "center",
            "@media screen and (max-width: 960px)": {
              position: "absolute",
              top: "120px",
            },
            "@media screen and (max-width: 640px)": {
              position: "absolute",
              top: "85px",
            },
          },
          deleteText: {
            textDecorationLine: "underline",
            "&:hover": {
              cursor: "pointer",
              color: "gray",
            },
            "@media screen and (max-width: 960px)": {
              marginRight: "5px",
            },
          },
          deleteIcon: {
            cursor: "pointer",
          },
        }));

        const QuantityInputWithButtons = ({ value, onChange, min, max, step, disabled }) => {
          const classes = useStyles();

          const handleDecrement = () => {
            if (value - step >= min) {
              onChange(value - step);
            }
          };

          const handleIncrement = () => {
            if (value + step <= max) {
              onChange(value + step);
            }
          };

          return (
            <StyledGrid className={classes.mainContainer}>
              <StyledGrid className={classes.container}>
                <button className={classes.button} onClick={handleDecrement} disabled={disabled || value <= min}>
                  -
                </button>
                <input
                  className={classes.input}
                  type="text"
                  value={value}
                  readOnly
                  min={min}
                  max={max}
                  step={step}
                  disabled={disabled}
                />
                <button className={classes.button} onClick={handleIncrement} disabled={disabled || value >= max}>
                  +
                </button>
              </StyledGrid>
              <StyledGrid className={classes.deleteContainer}>
                <StyledTypography
                  disabled={disabled}
                  color="primary"
                  onClick={() => removeItem(rowData)}
                  data-testid={`order-remove-item-button-${rowData.partNumber}`}>
                  <StyledTooltip title="Eliminar">
                    {props.miniCartView ? (
                      <StyledTypography className={classes.deleteText}>Eliminar</StyledTypography>
                    ) : (
                      <DeleteOutlineIcon className={classes.deleteIcon} />
                    )}
                  </StyledTooltip>
                </StyledTypography>
              </StyledGrid>
            </StyledGrid>
          );
        };

        const onQuantityUpdate = (quantityString: string, item: any) => {
          if (item) {
            try {
              const quantity = parseInt(quantityString);
              if (quantity > 0) {
                const payload = {
                  ...payloadBase,
                  quantity: quantity.toString(),
                  orderItemId: item.orderItemId,
                  fetchCatentries: true,
                };
                dispatch(orderActions.UPDATE_ITEM_ACTION(payload));

                // update this (even tho it's temporary) just so that once the dispatch
                //   action completes, we don't "temporarily" revert back to the old value
                quantityList[rowData.orderItemId] = quantity;
              }
            } catch (e) {
              console.log("Could not parse quantity");
            }
          }
        };
        return !miniCartView && readOnly ? (
          <QuantityDisplay rowData={rowData} />
        ) : (
          <QuantityInputWithButtons
            value={quantityList[rowData.orderItemId]}
            onChange={(newValue) => onQuantityUpdate(newValue.toString(), rowData)}
            min={1}
            max={999}
            step={1}
            disabled={disabled}
          />
        );
      };

      const EmptyTemplate = ({ condition }) => {
        if (condition) {
          return null; // No renderiza nada si la condición es verdadera
        }

        return <></>;
      };

      let columns: TableColumnDef[] = [
        {
          title: "",
          idProp: "orderItemId",
          keyLookup: {
            key: CONSTANTS.thumbnail,
          },
          display: {
            cellStyle: {
              textAlign: "center",
            },
            template: ThumbnailCell,
          },
        },
        {
          title: t("OrderItemTable.Labels.ItemDetails"),
          keyLookup: {
            key: CONSTANTS.name,
          },
          sortable: { fn: itemValCalc },
          display: {
            template: ItemDetailsCell,
          },
        },
        {
          title: t("OrderItemTable.Labels.Quantity"),
          keyLookup: {
            key: CONSTANTS.quantity,
          },
          sortable: { numeric: true, fn: quantityCalc },
          display: {
            cellStyle: {
              textAlign: "left",
            },
            template:
              /*  isCartView && !miniCartView && */ isMobile && showQuantityCell && showDeleteButton
                ? CombinedCell
                : showQuantityCell
                ? QuantityCell /* CombinedCell */
                : EmptyTemplate,
          },
        },
        {
          title: t("OrderItemTable.Labels.Price"),
          keyLookup: {
            key: CONSTANTS.orderItemPrice,
          },
          sortable: { numeric: true, fn: priceCalc },
          display: {
            cellStyle: {
              textAlign: "left",
            },
            template: miniCartView
              ? EmptyTemplate
              : ({ rowData, ...props }) => <OrderItemPrice rowData={rowData} miniCartView={miniCartView} />,
          },
        },
        {
          title: t("OrderItemTable.Labels.Actions"),
          keyLookup: {
            key: CONSTANTS.orderItemActions,
          },
          display: {
            cellStyle: {
              textAlign: "left",
            },

            template: (isMobile && !miniCartView) || !showDeleteButton ? EmptyTemplate : DeleteActionCell,
          },
        },
      ];

      if (readOnly) {
        columns = columns.filter((col) => col.keyLookup.key !== CONSTANTS.orderItemActions);
      }

      // don't show status if order hasn't been shipped
      if (preShip) {
        columns = columns.filter(({ keyLookup: { key: k } }) => k !== CONSTANTS.status);
      }

      if (miniCartView) {
        columns = columns.filter(
          (col) =>
            col.keyLookup.key !== CONSTANTS.orderItemInventoryStatus &&
            col.keyLookup.key !== CONSTANTS.quantity &&
            col.keyLookup.key !== CONSTANTS.orderItemPrice &&
            col.keyLookup.key !== CONSTANTS.orderItemActions
        );
      }

      return columns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      t,
      mySite,
      miniCartView,
      handleMiniCartClose,
      isRecurringOrderFeatureEnabled,
      contractId,
      dispatch,
      CancelToken,
      readOnly,
      quantityList,
      defaultCurrencyID,
      data,
    ]
  );

  /*   const panelExpanderComps = useMemo(
    () => ({
      compShow: OpenDrawer,
      compHide: CloseDrawer,
    }),
    []
  ); */

  useEffect(() => {
    // the order-items may just have had cosmetic updates, i.e., had attributes or some product-info attached --
    //   check to see if they've actually changed
    const needsUpdate =
      isEmpty(timestamps) ||
      Object.keys(timestamps).length !== dataProps.length ||
      !dataProps.every((oi) => oi.lastUpdateDate === timestamps[oi.orderItemId]);

    // quantity update required
    if (needsUpdate) {
      setTimestamps(dataProps.reduce((stamps, oi) => ({ ...stamps, [oi.orderItemId]: oi.lastUpdateDate }), {}));
      setQuantityList(initQuantityData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProps]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (seller) {
      setActionData({
        grids: [{ xs: true }],
        extraActions: [
          <div style={{ display: "flex", alignItems: "center" }}>
            <TruckIcon fontSize="large" className="right-margin-1" />
            <StyledTypography variant="h6">
              {t("productDetail.SellerSimple", { seller: seller.seller })}
            </StyledTypography>
          </div>,
        ],
      });
    }
  }, [seller]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data?.length && columns?.length) {
      // hide panel for any records without attributes
      data
        .filter((r) => !r.attributes?.length)
        .forEach((r) => setValueForCell(TableConstants.NOPANEL, true, r, columns, tableState, () => true));
      // bulk update -- this is why the setter function is set to a dummy tautology above
      setTableState({ ...tableState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, data]);

  return {
    columns,
    data,
    options,
    detailPanel: DetailPanel,
    labels: {
      labelRowsSelect: t("OrderItemTable.Labels.PageSizeLabel"),
      labelDisplayedRows: t("OrderItemTable.Labels.RowCount"),
      firstTooltip: t("OrderItemTable.Labels.FirstPage"),
      previousTooltip: t("OrderItemTable.Labels.PreviousPage"),
      nextTooltip: t("OrderItemTable.Labels.NextPage"),
      lastTooltip: t("OrderItemTable.Labels.LastPage"),
    },
    t,
    miniCartView,
    handleMiniCartClose,
    isCartView,
    /* panelExpanderComps, */
    actionData,
  };
};

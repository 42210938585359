/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import Grid from "@mui/material/Grid"; // Importa Grid de MUI
import Card from "@mui/material/Card"; // Importa Card de MUI
import CardContent from "@mui/material/CardContent"; // Importa CardContent de MUI
import Typography from "@mui/material/Typography"; // Importa Typography de MUI
import CardActions from "@mui/material/CardActions"; // Importa CardActions de MUI
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import getDisplayName from "react-display-name";
import { paramCase } from "change-case";
import { useLocation } from "react-router-dom";

import { RootState } from "../../redux/store/index";

//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
import categoryService from "../../_foundation/apis/search/categories.service";
import { STORELOCATORACTIONS } from "../../_foundation/constants/common";
import { localStorageUtil } from "../../_foundation/utils/storageUtil";
import { useStoreLocatorValue } from "../../_foundation/context/store-locator-context";
//Custom libraries
import { headerConfig } from "./headerConstant";
import { TOP_CATEGORIES_DEPTH_LIMIT } from "../../configs/catalog";
import { MINICART_CONFIGS } from "../../configs/order";
import * as ROUTES from "../../constants/routes";
import ContentRecommendationWidget from "../commerce-widgets/content-recommendation-widget";
import MiniCart from "./MiniCart";
import LanguageToggle from "./LanguageToggle";
import MegaMenu from "./MegaMenu";
import ExpandedMenu from "./ExpandedMenu";
import { SearchBar } from "../widgets/search-bar";
import AccountPopperContent from "./AccountPopperContent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LockIcon from "@mui/icons-material/Lock";
import logoMztFc from "../../assets/eaas-b2c-store/images/header/logoMztFc.png";

//Redux
import { userNameSelector, loginStatusSelector } from "../../redux/selectors/user";
import { addressDetailsSelector } from "../../redux/selectors/account";
import { ORG_SWITCH_ACTION } from "../../redux/actions/organization";
import { CONTRACT_SWITCH_ACTION } from "../../redux/actions/contract";
import { LOGOUT_REQUESTED_ACTION } from "../../redux/actions/user";
import { UPDATE_CATEGORIES_STATE_ACTION } from "../../redux/actions/category";
import { SELLERS_GET_ACTION } from "../../redux/actions/sellers";
import { currentContractIdSelector } from "../../redux/selectors/contract";
import { successSelector } from "../../redux/selectors/success";
import { SuccessMessageReducerState } from "../../redux/reducers/reducerStateInterface";
//UI
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, ClickAwayListener, IconButton, SvgIcon, useTheme } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StoreIcon from "@mui/icons-material/Store";
import { Hidden } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import Close from "@mui/icons-material/Close";
import {
  StyledAccountPopper,
  StyledButton,
  StyledContainer,
  StyledHeader,
  StyledHeaderActions,
  StyledTypography,
  StyledSwipeableDrawer,
  StyledGrid,
  StyledLink,
  StyledPaper,
  StyledBox,
  StyledSearchBarButton,
} from "../../elements";
import { useWinDimsInEM } from "../../_foundation/hooks/use-win-dims-in-em";
import { selectedSellersSelector, sellersSelector } from "../../redux/selectors/sellers";
import { SELLER_STORAGE_KEY } from "../../constants/common";
import { SET_SELLER_ACTION } from "../../redux/actions/sellers";
import { Sellers } from "./sellers";
import { StyledList } from "@hcl-commerce-store-sdk/react-component";
import { StoreLocatorButton } from "./store-locator-button";
import "../custom/header/eaas.css";
// import { makeStyles } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import { HeaderAccountNameTextContainer, IconContainer } from "./HeaderStyles";
import EspotMzFC from "components/custom/eSpot/EspotMzFC";
import { ArrowBackIos } from "@mui/icons-material";
import zIndex from "@mui/material/styles/zIndex";
interface HeaderProps {
  loggedIn: boolean;
}

const Buttonify = ({ children, ...props }) => {
  const { testId } = props;
  return (
    <StyledButton testId={testId} className="header-actionsButton" variant="text" color="secondary" {...props}>
      <StyledHeaderActions>{children}</StyledHeaderActions>{" "}
    </StyledButton>
  );
};

const MarketplacePopper = ({ sellerConfig }) => {
  const btnRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const guestSellerPicker = "guestSellerPicker";
  const { t } = useTranslation();
  const { mySite } = useSite();
  const onClickAway = useCallback((e) => {
    if (e.target.localName === "body") {
      return;
    }
    setOpen(false);
  }, []);

  return !mySite?.isB2B && sellerConfig.showSellerList ? (
    <>
      <div ref={btnRef}>
        <Buttonify testId="header-mp" onClick={setOpen.bind(null, !open)}>
          <StyledBox display="flex" flexDirection="column" alignItems="center" flexWrap="wrap">
            <StoreIcon />
            <StyledTypography variant="body1" component="p">
              {t("sellers.mp")}
            </StyledTypography>
          </StyledBox>
        </Buttonify>
      </div>
      <StyledAccountPopper
        id={guestSellerPicker}
        open={open}
        anchorEl={btnRef.current}
        placement="bottom-end"
        modifiers={[
          { name: "flip", enabled: false },
          { name: "preventOverflow", enabled: true },
          { name: "hide", enabled: false },
        ]}
        className="account-popper">
        <ClickAwayListener onClickAway={onClickAway}>
          <StyledPaper className="vertical-padding-1 horizontal-padding-1">
            <StyledList disablePadding>
              <Sellers />
            </StyledList>
          </StyledPaper>
        </ClickAwayListener>
      </StyledAccountPopper>
    </>
  ) : null;
};

/**
 * Header component
 * displays Header, Mini Cart and Mega Menu
 * @param props
 */
const Header: React.FC<HeaderProps> = (props: any) => {
  const widgetName = getDisplayName(Header);
  const { w } = useWinDimsInEM();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
  const [topCategories, setTopCategories] = useState<Array<any>>([]);
  const [myAccountPopperOpen, setMyAccountPopperOpen] = useState<boolean>(false);

  const myAccountElRef = useRef<HTMLButtonElement>(null);

  const [miniCartPopperOpen, setMiniCartPopperOpen] = useState<boolean>(false);
  const miniCartElRef = useRef<HTMLButtonElement>(null);

  const [languageTogglePopperOpen, setLanguageTogglePopperOpen] = useState<boolean>(false);

  const languageToggleElRef = useRef<HTMLButtonElement>(null);

  const { mySite } = useSite();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const addressDetails = useSelector(addressDetailsSelector);
  const userName = useSelector(userNameSelector);
  const firstName = addressDetails?.firstName ?? userName?.firstName;
  const lastName = addressDetails?.lastName ?? userName?.lastName;
  const contractId = useSelector(currentContractIdSelector);
  const success: SuccessMessageReducerState = useSelector(successSelector);
  const userLoggedIn = useSelector(loginStatusSelector);
  const selectedSellers = useSelector(selectedSellersSelector);
  const sellerConfig = useSelector(sellersSelector);
  const sellers = localStorageUtil.get(SELLER_STORAGE_KEY);

  const userPreviousLoggedIn = useRef();

  const isB2B = Boolean(mySite?.isB2B);
  const loggedIn = props.loggedIn;
  const isShoppingEnabled = !isB2B || (isB2B && loggedIn);

  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));
  const isTablet = !useMediaQuery(theme.breakpoints.up("md"));

  const myAccountPopperId = "HEADER_MY_ACCOUNT_Popper";
  const miniCartPopperId = "HEADER_MINI_CART_Popper";
  const languageTogglePopperId = "HEADER_LANGUAGE_TOGGLE_Popper";
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];
  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };
  const payload = {
    ...payloadBase,
  };

  const navHome = () => navigate(ROUTES.HOME);
  const storeLocatorDispach = useStoreLocatorValue().dispatch;

  const handleMyAccountClick = () => {
    setMyAccountPopperOpen(true);
    setMiniCartPopperOpen(false);
    setLanguageTogglePopperOpen(false);
  };
  const handleMyAccountPopperClose = () => setMyAccountPopperOpen(false);

  const handleMiniCartClick = () => {
    setMiniCartPopperOpen(true);
    setMyAccountPopperOpen(false);
    setLanguageTogglePopperOpen(false);

    setTimeout(() => {
      window.scrollTo(0, 0);
    });
    setTimeout(() => {
      if (miniCartElRef !== null && miniCartElRef.current !== null) {
        miniCartElRef.current.focus();
      }
    }, 100);
  };

  const handleMiniCartPopperClose = useCallback(() => setMiniCartPopperOpen(false), []);

  const handleLanguageToggleClick = () => {
    setLanguageTogglePopperOpen(true);
    setMiniCartPopperOpen(false);
    setMyAccountPopperOpen(false);
  };
  const handleLanguageTogglePopperClose = () => {
    setLanguageTogglePopperOpen(false);
  };

  const handleOrgChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.persist();
    event.preventDefault();
    const orgId = event.target.value;
    dispatch(
      ORG_SWITCH_ACTION({
        query: { activeOrgId: String(orgId) },
        callback: navHome,
        ...payload,
      })
    );
  };

  const handleContractChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.persist();
    event.preventDefault();
    const conId = event.target.value;
    dispatch(
      CONTRACT_SWITCH_ACTION({
        query: { contractId: String(conId) },
        callback: navHome,
        ...payloadBase,
      })
    );
  };

  const handleLogout = (event) => {
    event.preventDefault();
    const param: any = {
      ...payload,
    };
    dispatch(LOGOUT_REQUESTED_ACTION(param));
    storeLocatorDispach({ type: STORELOCATORACTIONS.RESET_STORE_SELECTOR });
  };

  useEffect(() => {
    if (!userLoggedIn && userPreviousLoggedIn.current) {
      setMyAccountPopperOpen(false);
      setMiniCartPopperOpen(false);
      navHome();
    }
    userPreviousLoggedIn.current = userLoggedIn;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn]);

  useEffect(() => {
    if (mySite !== null && contractId !== undefined) {
      const storeID: string = mySite.storeID;
      const parameters: any = {
        storeId: storeID,
        depthAndLimit: TOP_CATEGORIES_DEPTH_LIMIT,
        query: {
          contractId: contractId,
        },
        ...payload,
      };
      categoryService
        .getV2CategoryResourcesUsingGET(parameters)
        .then((res) => {
          setTopCategories(res.data.contents);
          dispatch(UPDATE_CATEGORIES_STATE_ACTION(res.data.contents));
        })
        .catch((e) => {
          setTopCategories([]);
          dispatch(UPDATE_CATEGORIES_STATE_ACTION([]));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite, contractId, language, selectedSellers]);

  useEffect(() => {
    const payloadBase: any = {
      widget: widgetName,
      cancelToken: new CancelToken(function executor(c) {
        cancels.push(c);
      }),
    };
    dispatch(SELLERS_GET_ACTION(payloadBase));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    if (success && success.key) {
      if (MINICART_CONFIGS.itemAddSuccessMsgKeys.includes(success.key)) {
        handleMiniCartClick();
      }
    }
  }, [success]);

  useEffect(() => {
    //splice to empty array
    cancels.splice(0, cancels.length).forEach((cancel) => {
      cancel();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sellerConfig.initialized) {
      if (sellers?.length && !sellerConfig.showSellerList) {
        dispatch(SET_SELLER_ACTION({ sellers: null }));
      } else if (sellers?.length && !selectedSellers?.length) {
        dispatch(SET_SELLER_ACTION({ sellers }));
      }
    }
  }, [sellerConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  const crwProps = useMemo(
    () => ({
      widget: {
        id: `header-${paramCase(headerConfig.espot.eSpotName)}`,
        widgetName: "content-recommendation-widget",
        name: headerConfig.espot.eSpotName,
        properties: {
          emsName: headerConfig.espot.eSpotName,
        },
      },
      page: { name: "" },
    }),
    //Content is language sensitive, so listen to translation change to render.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const toggleSearchBar = () => setShowSearchBar(!showSearchBar);
  const turnOffSearchBar = () => setShowSearchBar(false);
  const turnOnSearchBar = () => setShowSearchBar(true);
  const toggleOpen = () => setOpen(!open);
  const turnOffOpen = () => setOpen(false);
  const turnOnOpen = () => setOpen(true);

  const handleBack = () => {
    navigate("/cart");
  };

  const handleHome = () => {
    navigate("/");
  };

  const location = useLocation();

  useEffect(() => {
    // Lógica para cerrar el drawer
    turnOffOpen(); // Suponiendo que esta función cierra el drawer
  }, [location]);

  const drawerAnchor = useMediaQuery(theme.breakpoints.up("sm")) ? "left" : "left";

  const isScreenSmall = useMediaQuery("(max-width:599px)");

  const isCheckoutView = useSelector((state: RootState) => state.checkout.isCheckoutView);

  const headerStyles = useMemo(
    () =>
      makeStyles((theme) => ({
        headerSticky: {
          position: "fixed",
          width: "100%",
          height: "auto",
          top: "0",
          left: "0",
          zIndex: "999",
        },

        hidden: {
          display: "none",
        },
        headerContainer: {
          maxWidth: "100%",
          height: "auto",
          paddingLeft: "0",
          paddingRight: "0",
          marginLeft: "0",
          marginRight: "0",
          "@media (max-width: 960px)": {
            display: showSearchBar ? "none" : "",
          },
        },

        containerIcons: {
          paddingRight: "67px",
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "0",

          "@media (max-width: 900px)": {
            paddingRight: "14px",
          },
        },
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "none",
        },
        searchIcon: {
          color: "white",
          "&:hover": {
            cursor: "pointer",
            color: "#5C2896",
          },
          display: showSearchBar ? "none" : "",
        },
        accountIcon: {
          color: "white !important",
          "&:hover": {
            cursor: "pointer",
            color: "#5C2896 !important",
          },
        },
        searchBar: {
          padding: "0px",
          maxWidth: "225px",
          "@media (max-width: 960px)": {
            padding: "10px",
            maxWidth: "100%",
          },
        },
        drawerContainer: {
          width: "100%",
          height: "100%",
        },
        menuContainer: {
          padding: "0",
          margin: "0",
          width: "auto",
          maxWidth: "100%",
          height: "100%",
          display: "none",
          marginTop: "50px",
        },
        open: {
          display: "block",
        },

        /* ALTER HEADER */

        alterHeaderContainer: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#0F1820",
          color: "white",
          maxWidth: "100%",
          height: "80px",
          paddingLeft: theme.spacing(4.5),
          paddingRight: theme.spacing(4.5),
          "@media (max-width: 40rem)": {
            paddingLeft: "8px",
            paddingRight: "8px",
          },
          "@media (min-width: 40rem)": {
            height: "80px !important",
          },
        },
        backButton: {
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          "&:hover": {
            textDecoration: "underline",
          },
        },
        securePurchase: {
          display: "flex",
          alignItems: "center",
        },
        logoMztFc: {
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          height: "50px",
        },
        iconContainer: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "40px",
          height: "40px",
        },
      })),
    [showSearchBar]
  );
  const classesHeader = headerStyles();

  return (
    <>
      {isCheckoutView ? (
        <StyledHeader className={classesHeader.headerSticky}>
          <StyledContainer className={classesHeader.alterHeaderContainer}>
            {/*Mobile */}
            {isMobile ? (
              <>
                <div
                  className={classesHeader.backButton}
                  onClick={handleBack}
                  style={{ width: "85px", marginLeft: "5px" }}>
                  <ArrowBackIos style={{ width: "18px" }} />
                  <Typography style={{ marginLeft: "2px", font: "normal normal normal 13px/17px Rubik" }}>
                    Regresar a mi carrito
                  </Typography>
                </div>
                <div className={classesHeader.logoMztFc} onClick={handleHome} style={{ marginLeft: "-5px" }}>
                  <EspotMzFC espotName="logoFcMzt" />
                </div>
                <div className={classesHeader.securePurchase}>
                  <LockIcon />
                  <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px", marginRight: "5px" }}>
                    <Typography style={{ font: "normal normal bold 12px/17px Rubik" }}>COMPRA</Typography>
                    <Typography style={{ font: "normal normal bold 12px/17px Rubik" }}>SEGURA</Typography>
                  </div>
                </div>
              </>
            ) : (
              //Desktop
              <>
                <div className={classesHeader.backButton} onClick={handleBack}>
                  <ArrowBackIos style={{ width: "18px" }} />
                  <Typography style={{ marginLeft: "2px" }}>Regresar a mi carrito</Typography>
                </div>
                <div className={classesHeader.logoMztFc} onClick={handleHome}>
                  <EspotMzFC espotName="logoFcMzt" />
                </div>
                <div className={classesHeader.securePurchase}>
                  <LockIcon />
                  <Typography style={{ marginLeft: "5px", font: "normal normal bold 13px/17px Rubik" }}>
                    COMPRA SEGURA
                  </Typography>
                </div>
              </>
            )}
          </StyledContainer>
        </StyledHeader>
      ) : (
        <StyledHeader className={classesHeader.headerSticky}>
          <StyledBox>
            <EspotMzFC espotName="MainHeader_SuperiorSection" />
          </StyledBox>
          <StyledContainer className={classesHeader.headerContainer}>
            <StyledGrid container justifyContent="space-between" alignItems="center">
              <StyledGrid item>
                <StyledGrid container justifyContent="space-between" alignItems="center">
                  <StyledGrid item>
                    <Hidden mdUp>
                      <IconButton className="menu-hamburger" data-testid="menu-hamburger-element" onClick={toggleOpen}>
                        {open ? <Close /> : <MenuIcon />} {/* Cambio condicional del icono */}
                      </IconButton>
                    </Hidden>
                  </StyledGrid>

                  {mySite != null && (
                    <StyledGrid item>
                      <div className="header-branding">
                        <ContentRecommendationWidget {...crwProps} />
                      </div>
                    </StyledGrid>
                  )}
                </StyledGrid>
              </StyledGrid>
              <StyledGrid item>
                <StyledGrid item>
                  <Hidden mdDown>
                    <ExpandedMenu pages={topCategories} />
                  </Hidden>
                </StyledGrid>
              </StyledGrid>
              <StyledGrid item className={classesHeader.containerIcons}>
                <StyledGrid className="header-topbarSection" container alignItems="center" direction="row">
                  {/* MOBILE */}
                  <Hidden mdUp>
                    <StyledGrid
                      item
                      data-testid="search-bar-mobile-largetablet-element"
                      style={{ display: "flex", flexDirection: "row", color: "white" }}>
                      {showSearchBar && (
                        <>
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                            }}
                            onClick={() => setShowSearchBar(false)}></div>
                        </>
                      )}
                      <StyledButton onClick={toggleSearchBar} variant="text" style={{ color: "white" }}>
                        <SearchIcon fontSize="large" className={classesHeader.searchIcon} />
                      </StyledButton>
                    </StyledGrid>
                  </Hidden>

                  {/* DESKTOP */}
                  <Hidden mdDown>
                    <StyledGrid
                      item
                      data-testid="search-bar-desktop-largetablet-element"
                      style={{ display: "flex", flexDirection: "row", color: "white" }}
                      className={classesHeader.searchBar}>
                      {showSearchBar && (
                        <>
                          <div
                            style={{
                              position: "fixed",
                              top: "0px",
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundColor: "rgba(0, 0, 0, 0.2)",
                            }}
                            onClick={() => setShowSearchBar(false)}></div>

                          <SearchBar
                            showSearchBar={showSearchBar}
                            closeSearchBar={turnOffSearchBar}
                            openSearchBar={turnOnSearchBar}
                          />
                        </>
                      )}
                      <StyledButton onClick={toggleSearchBar} variant="text" style={{ color: "white" }}>
                        <SearchIcon fontSize="large" className={classesHeader.searchIcon} />
                      </StyledButton>
                    </StyledGrid>
                  </Hidden>

                  <StyledGrid item>
                    {loggedIn ? (
                      <StyledGrid item>
                        <button
                          ref={myAccountElRef}
                          /* variant="text" */
                          color="secondary"
                          className="header-actionsButton"
                          style={{ backgroundColor: "transparent", border: "none", color: "white", cursor: "pointer" }}
                          onClick={handleMyAccountClick}
                          /* testId="header-action-account" */
                        >
                          {/*                           {isTablet ? (
                            <StyledHeaderActions>
                              <AccountBoxIcon />
                              <StyledTypography variant="body1" component="p">
                                {isMobile ? t("Header.Actions.Account") : t("Header.Actions.YourAccount")}
                              </StyledTypography>
                            </StyledHeaderActions>
                          ) : ( */}
                          <StyledGrid
                            xs={12}
                            container
                            className="welcome-text"
                            direction="column-reverse"
                            alignItems="center">
                            <HeaderAccountNameTextContainer item xs={9}>
                              <StyledTypography
                                variant="button"
                                component="p"
                                className={`account-welcome ${myAccountPopperOpen ? classesHeader.hidden : ""}`}>
                                {firstName ? (
                                  <>{t("Header.Actions.WelcomeFirstname", { firstName })}</>
                                ) : (
                                  t("Header.Actions.WelcomeNoFirstname", {
                                    lastName,
                                  })
                                )}
                              </StyledTypography>
                            </HeaderAccountNameTextContainer>
                            <StyledGrid
                              item
                              xs={3}
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              flexWrap="wrap">
                              {myAccountPopperOpen ? (
                                <SvgIcon fontSize="large" className={classesHeader.accountIcon}>
                                  <PersonOutlineOutlinedIcon />
                                </SvgIcon>
                              ) : (
                                <IconContainer fontSize="large" className={classesHeader.accountIcon}>
                                  <PersonOutlineOutlinedIcon />
                                </IconContainer>
                              )}
                            </StyledGrid>
                          </StyledGrid>
                          {/* )} */}
                        </button>
                        <StyledAccountPopper
                          id={myAccountPopperId}
                          open={myAccountPopperOpen}
                          anchorEl={myAccountElRef.current}
                          onClose={handleMyAccountPopperClose}
                          placement={w <= 40 ? "bottom" : "bottom-end"}
                          modifiers={[
                            {
                              name: "flip",
                              enabled: false,
                            },
                            {
                              name: "preventOverflow",
                              enabled: false,
                            },
                            {
                              name: "hide",
                              enabled: false,
                            },
                            {
                              name: "offset",
                              options: {
                                offset: [isMobile ? -70 : 0, 0], // Ajusta el primer valor negativo para mover más a la izquierda
                              },
                            },
                          ]}
                          className="account-popper">
                          <ClickAwayListener onClickAway={handleMyAccountPopperClose}>
                            <StyledPaper className="horizontal-padding-2">
                              <StyledTypography variant="body1" component="div">
                                <AccountPopperContent
                                  handleClose={handleMyAccountPopperClose}
                                  handleOrgChange={handleOrgChange}
                                  handleContractChange={handleContractChange}
                                  handleLogout={handleLogout}
                                  isB2B={isB2B}
                                  userName={{ firstName, lastName }}
                                />
                              </StyledTypography>
                            </StyledPaper>
                          </ClickAwayListener>
                        </StyledAccountPopper>
                      </StyledGrid>
                    ) : (
                      <StyledGrid item style={{ display: "flex", alignItems: "center" }}>
                        <StyledLink to={ROUTES.SIGNIN}>
                          <button
                            ref={myAccountElRef}
                            /* variant="text" */
                            color="secondary"
                            className="header-actionsButton"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              color: "white",
                              cursor: "pointer",
                            }}
                            onClick={handleMyAccountClick}
                            /* testId="header-action-account" */
                          >
                            <>
                              {isMobile ? (
                                <>
                                  <PersonOutlineOutlinedIcon fontSize="large" className={classesHeader.accountIcon} />
                                </>
                              ) : (
                                <>
                                  <PersonOutlineOutlinedIcon fontSize="large" className={classesHeader.accountIcon} />
                                </>
                              )}
                            </>
                          </button>
                        </StyledLink>
                      </StyledGrid>
                    )}
                  </StyledGrid>
                  <StyledGrid item>
                    {isShoppingEnabled && (
                      <StyledGrid item>
                        <MiniCart
                          id={miniCartPopperId}
                          open={miniCartPopperOpen}
                          handleClick={handleMiniCartClick}
                          handleClose={handleMiniCartPopperClose}
                          ref={miniCartElRef}
                        />
                      </StyledGrid>
                    )}
                    {sellerConfig?.showSellerList ? <MarketplacePopper {...{ sellerConfig }} /> : null}
                  </StyledGrid>
                </StyledGrid>
              </StyledGrid>
            </StyledGrid>
          </StyledContainer>

          {showSearchBar && (
            <Hidden mdUp>
              <StyledContainer className={classesHeader.searchBar}>
                <SearchBar
                  showSearchBar={showSearchBar}
                  closeSearchBar={turnOffSearchBar}
                  openSearchBar={turnOnSearchBar}
                />
              </StyledContainer>
            </Hidden>
          )}

          <Hidden mdUp>
            <StyledSwipeableDrawer
              anchor={drawerAnchor}
              open={open}
              onClose={turnOffOpen}
              onOpen={turnOnOpen}
              className={classesHeader.drawerContainer}
              data-testid="header-menu-drawer-element"
              PaperProps={{
                style: {
                  backgroundColor: "#0F1820",
                  maxWidth: "100%",
                  width: "100%",
                  borderTop: "none",
                  boxShadow: "none",
                  padding: "0",
                },
              }}>
              <StyledContainer>
                {/* Botón para cerrar el drawer */}
                {/* {isScreenSmall && ( */}
                <IconButton
                  onClick={turnOffOpen}
                  style={{
                    position: "absolute",
                    left: 4,
                    top: 4,
                    color: "white",
                    marginBottom: "10px",
                  }}>
                  <Close style={{ fontSize: "1.8em" }} />
                </IconButton>

                <StyledGrid
                  container
                  spacing={2}
                  className={`${classesHeader.menuContainer} ${open ? classesHeader.open : ""}`}>
                  <MegaMenu pages={topCategories} closeMegaMenu={turnOffOpen}></MegaMenu>
                </StyledGrid>
              </StyledContainer>
            </StyledSwipeableDrawer>
          </Hidden>
        </StyledHeader>
      )}
    </>
  );
};

export { Header };

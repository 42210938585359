// Acciones
const OPEN_DRAWER = "OPEN_DRAWER";
const CLOSE_DRAWER = "CLOSE_DRAWER";

// Action Creators
export const openDrawer = () => ({
  type: OPEN_DRAWER,
});

export const closeDrawer = () => ({
  type: CLOSE_DRAWER,
});

// Estado inicial
const initialState = {
  isOpen: false,
};

// Reducer
const drawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_DRAWER:
      return { ...state, isOpen: true };
    case CLOSE_DRAWER:
      return { ...state, isOpen: false };
    default:
      return state;
  }
};

export default drawerReducer;

// src/redux/reducers/reviewReducer.ts
import { ReviewActions, SET_REVIEW_VIEW } from "../actions/is-review";

export interface ReviewState {
  isReviewView: boolean;
}

const initialState: ReviewState = {
  isReviewView: false,
};

const reviewReducer = (state = initialState, action: ReviewActions): ReviewState => {
  switch (action.type) {
    case SET_REVIEW_VIEW:
      return {
        ...state,
        isReviewView: action.payload,
      };
    default:
      return state;
  }
};

export default reviewReducer;

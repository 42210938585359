/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import React from "react";
import styled from "@mui/styled-engine-sc";
import Breadcrumbs from "@mui/material/Breadcrumbs";
// import NavigateNextIcon from "@mui/icons-material/NavigateNext";
// import LensIcon from "@mui/icons-material/Lens";

const BreadcrumbWrapper = (props: any) => <Breadcrumbs {...props} separator="•" />;

export const StyledBreadcrumbs = styled(BreadcrumbWrapper)`
  ${({ theme }) => `
    margin: ${theme.spacing(4)} 0;
    @media screen and (max-width: 960px) {
      margin: 9px 0 !important;
    }
  `}
`;

import styled from "@mui/styled-engine-sc";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar, A11y } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const StyledSlideContainer = styled(({ ...props }) => <div {...props} />)`
  ${({ theme, scrollBarColor }) => `
  position: relative;
  width: calc(100% - 40px);
  height: auto;
  padding-bottom: 10px;

  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }


.swiper {
  padding-bottom: 110px;
  ${theme.breakpoints.down("768")} {
    padding-bottom: 70px;
  }
}

.swiper-wrapper {
  margin-left: 40px;
  ${theme.breakpoints.down("sm")} {
   margin-left: 0px;
   height: 364px;
  }
}

.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  width: 249px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${theme.breakpoints.down("sm")} {
    width: 180px;
  }
}
.swiper-slide p {
  font: normal normal normal 14px/17px Rubik;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
  height: 34px;
  overflow:hidden;
}
.swiper-slide span {
  text-align:left;
  font: normal normal 600 15px/18px Rubik;
  letter-spacing: 0px;
}
.swiper-slide img {
  maxWidth: 100%;
  maxHeight: 100%;
  width: 249px;
  object-fit: cover;
  height: 329px;
  borderRadius: 0;
  background: transparent;
  ${theme.breakpoints.down("sm")} {
    width: 180px;
    height: 238px;
  }
}
.swiper-scrollbar.swiper-scrollbar-horizontal {
  height: 8px;
  bottom: 65px;
}
.swiper-scrollbar {
  bottom: 0;
  height: 8px;
  .swiper-scrollbar-drag {
    border-radius: 10px;
    height: 8px;
    background: ${scrollBarColor};
  }
}
.swiper-button-next, .swiper-button-prev {
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  width: 30px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  top: 35%;
}

.swiper-button-disabled {
  display: none;
}

.swiper-button-next:hover, .swiper-button-prev:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 25px !important;
}
`}
`;

const StyledCartRecommendationSlider = ({ slidesList, widget }) => {
  let scrollBarColor = "white";

  if (widget.name === "CartPageProductRecommendation") {
    scrollBarColor = "black";
  }
  if (widget.name === "OrderConfirmationPageProductRecommendation") {
    scrollBarColor = "black";
  }
  if (widget.name === "PDProductRecommendation") {
    scrollBarColor = "black";
  }
  return (
    <StyledSlideContainer scrollBarColor={scrollBarColor}>
      <Swiper
        modules={[Navigation, Scrollbar, A11y]}
        slidesPerView={widget.name === "PDProductRecommendation" ? 5 : 5}
        navigation
        scrollbar={{ draggable: true }}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 2,
            spaceBetween: 7,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 7,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3,
            spaceBetween: 7,
          },
          820: {
            slidesPerView: 4,
            spaceBetween: 18,
          },
          1080: {
            slidesPerView: widget.name === "PDProductRecommendation" ? 5 : 5,
            spaceBetween: 18,
          },
          // 1200: {
          //   slidesPerView: 4,
          // },
          // when window width is >= 1024px
          1440: {
            slidesPerView: widget.name === "PDProductRecommendation" ? 5 : 5,
            spaceBetween: 18,
          },
        }}>
        {slidesList.map((slideContent, index) => (
          <SwiperSlide key={index} className="swiper-slide">
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledSlideContainer>
  );
};

export { StyledCartRecommendationSlider };

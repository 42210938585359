// src/redux/reducers/checkoutReducer.ts
import { CheckoutActions, SET_CHECKOUT_VIEW } from "../actions/is-checkout";

export interface CheckoutState {
  isCheckoutView: boolean;
}

const initialState: CheckoutState = {
  isCheckoutView: false,
};

const checkoutReducer = (state = initialState, action: CheckoutActions): CheckoutState => {
  switch (action.type) {
    case SET_CHECKOUT_VIEW:
      return {
        ...state,
        isCheckoutView: action.payload,
      };
    default:
      return state;
  }
};

export default checkoutReducer;

// src/redux/actions/is-checkout.ts
export const SET_CART_VIEW = "SET_CART_VIEW";

// Define una interfaz para el tipo de acción
export interface SetCartViewAction {
  type: typeof SET_CART_VIEW;
  payload: boolean;
}

// Función creadora de acciones
export function setCartView(isCartView: boolean): SetCartViewAction {
  return {
    type: SET_CART_VIEW,
    payload: isCartView,
  };
}

// Tipo de exportación para ser utilizado en el reducer
export type CartActions = SetCartViewAction;

/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect, useState } from "react";
//Custom libraries
import { OrderItemTable } from "../../widgets/order-item-table";
import FormattedPriceDisplay from "../../widgets/formatted-price-display";
//UI
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, IconButton } from "@mui/material";
import {
  StyledBox,
  StyledButton,
  StyledGrid,
  StyledTextField,
  StyledTypography,
} from "@hcl-commerce-store-sdk/react-component";
import { useCart } from "_foundation/hooks/use-cart";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { KeyboardArrowDown } from "@mui/icons-material";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { StyledPaper } from "elements";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { setCartView } from "redux/actions/is-cart";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Add this import

interface MiniCartContentProps {
  title: string;
  orderType: string;
  orderItems: any[];
  subtotalLabel: string;
  subtotal: number | null;
  subtotalCurrency: string;
  shipping: string;
  promoAsk: string;
  total: string;
  taxes: string;
  emptyCartMsg: string;
  emptyCartButton: string;
  cartLinkLabel: string;
  checkoutLinkLabel: string;
  handleCartOnClick: (e?: any) => void;
  handleCheckoutOnClick: (e?: any) => void;
  handleCloseDrawer: (e?: any) => void;
  isOrderOwner: boolean;
  isB2B: boolean;
}

const StyledMiniCartContent = React.forwardRef<any, MiniCartContentProps>((props: any, ref: any) => {
  const {
    title,
    orderType,
    orderItems,
    subtotalLabel,
    subtotal,
    subtotalCurrency,
    shipping,
    promoAsk,
    total,
    taxes,
    emptyCartMsg,
    emptyCartButton,
    cartLinkLabel,
    checkoutLinkLabel,
    handleCartOnClick,
    handleCheckoutOnClick,
    handleCloseDrawer,
    isOrderOwner,
    isB2B,
  } = props;

  const {
    // isFetching,
    // isRecurringOrderFeatureEnabled,
    // isRecurringOrder,
    // isRecurringOrderDisabled,
    // recurringOrderFrequency,
    // setIsRecurringOrder,
    // setRecurringOrderFrequency,
    // hasDiscounts,
    // recurringOrderStartDate,
    // frequencyOptions,
    // localeMap,
    // locale,
    // onDateChange,
    // onDateError,
    promoCode,
    resetPromoCodeError,
    onPromoCodeChange,
    applyPromoCodeBasedOnKey,
    promoCodeError,
    applyPromoCode,
    // selectedPromoCodes,
    // onPromoCodeRemove,
    cart,
    // canContinue,
    // checkout,
    // selectedProfile,
    // setSelectedProfile,
    // partitionedBySellers,
  } = useCart();

  const { t } = useTranslation();

  // Estado para controlar la visibilidad del contenedor del código promocional
  const [showPromoCode, setShowPromoCode] = useState(false);

  // Función para alternar la visibilidad
  const togglePromoCodeVisibility = () => {
    setShowPromoCode(!showPromoCode);
  };

  /* Mini Cart Content Styles */
  const MCCStyles = makeStyles((theme) => ({
    MainContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      background: "#FAFBFB",
      boxShadow: "none",
      padding: "0px",
    },
    headerContainer: {
      display: "flex",
      position: "sticky",
      top: 0,
      justifyContent: "space-between",
      alignItems: "center",
      height: theme.spacing(12),
      background: "#F8F7F7 0% 0% no-repeat padding-box",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(2.5),
      width: "100%",
      zIndex: 1000,
      "@media (max-width: 40em)": {
        height: theme.spacing(9),
      },
    },
    headerText: {
      color: "#272633",
      fontSize: "20px",
      font: "normal normal bold 18px/22px Rubik",
      textTransform: "uppercase",
      width: "auto",
      paddingLeft: "5px",
      paddingRight: "50px",
      "@media (max-width: 40em)": {
        fontSize: "16px",
        font: "normal normal bold 16px/20px Rubik",
        textTransform: "uppercase",
        width: "auto",
        paddingRight: "0px",
      },
    },
    closeButton: {
      display: "flex",
      justifyContent: "flex-end",
      color: "#CBCBCB",
      background: "0% 0% no-repeat padding-box",
      margin: 0,
      padding: 0,
      marginBottom: theme.spacing(3),
      "@media (max-width: 40em)": {
        marginBottom: theme.spacing(0),
      },
    },
    miniCartContainer: {
      position: "sticky",
      bottom: 0,
      "& div": {
        boxShadow: "none",
        /* marginBottom: theme.spacing(1), */
        background: "#FAFBFB",
      },
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      "@media (max-width: 40em)": {},
    },
    orderItemTableContainer: {
      flex: 1,
      overflow: "auto",
      width: "100%",
      "& tbody": {
        background: "#FAFBFB",
        "& tr": {
          "& td.pivoted": {
            whiteSpace: "normal",
          },
        },
      },
      "& div": {
        boxShadow: "none",
        marginBottom: theme.spacing(1),
        background: "#FAFBFB",
      },

      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
      "@media (max-width: 40em)": {
        /* marginTop: theme.spacing(9), */
      },
    },
    downPartContainer: {
      position: "sticky",
      bottom: 0,
    },
    subtotalesText: {
      font: "normal normal bold 15px/18px Rubik",
      color: "#4B4B4B",
      marginTop: theme.spacing(2),
    },
    codigoPromocional: {
      fontWeight: "bold",
    },
    totales: {
      textTransform: "uppercase",
      font: "normal normal bold 19px/23px Rubik",
      color: "#4B4B4B",
    },
    /* Titulo (Añadido recientemente a la bolsa) */
    taxes: {
      color: "#989898",
      font: "normal normal normal 11px/13px Rubik",
    },
    buttonPromo: {
      width: "100%",
      justifyContent: "space-between",
      marginTop: theme.spacing(-1),
      marginBottom: theme.spacing(0),
    },
    closePromo: {
      position: "absolute",
      right: theme.spacing(1),
    },
    promoContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
    },
    applyButton: {
      marginLeft: theme.spacing(2),
      justifyContent: "center",
      textTransform: "uppercase",
      padding: "11px 16px !important",
      font: "normal normal bold 15px/18px Rubik",
      "&:hover": {
        padding: "11px 16px !important",
      },
      "@media (max-width: 40em)": {
        width: "80%",
      },
      "&.MuiButton-containedPrimary": {
        "&:hover": {
          background: "#5C2896",
        },
      },
    },
    TextFieldPromo: {
      width: "100%",
      marginTop: theme.spacing(2),
      font: "normal normal bold 19px/23px Rubik",
      color: "#4B4B4B",
      "& .MuiOutlinedInput-root": {
        borderRadius: "6px",
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "2px solid #5C2896",
        },
      },
    },
    buttonsContainer: {
      background: "#FAFBFB",
      display: "flex",
      position: "sticky",
      bottom: 0,
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: theme.spacing(2),
      "& button": {
        marginTop: theme.spacing(1.5),
        justifyContent: "center",
        width: "85%",
        textTransform: "uppercase",
        font: "normal normal bold 15px/18px Rubik",
        height: "50px",
        "@media (max-width: 40em)": {
          width: "86%",
        },
      },
      "@media (max-width: 40em)": {
        justifyContent: "flex-end",
        flexGrow: 1,
        marginTop: "auto",
        marginBottom: theme.spacing(1),
      },
    },
    pagarButton: {
      background: "#533278",
      "&.MuiButton-containedPrimary": {
        border: "#5C2896",
        "&:hover": {
          background: "#5C2896",
        },
      },
    },
    bolsaButton: {
      "&.MuiButton-containedSecondary": {
        color: "#272633",
        background: "transparent",
        border: "2px solid #272633",
        "&:hover": {
          border: "2px solid #5C2896",
        },
      },
    },
    emptyContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
    },
    emptyCart: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "40%",
      font: "normal normal normal 19px/25px Rubik",
      color: "#818181",
      height: "auto",
      marginLeft: "20%",
      marginRight: "20%",
      "& svg": {
        fontSize: "100px",
        color: "#A5A6AA",
      },
    },
    seguirComprandoButton: {
      width: "80%",
      background: "#533278",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      textTransform: "uppercase",
      font: "normal normal bold 15px/18px Rubik",
      marginTop: "20%",
      height: "50px",
      borderRadius: "8px",
      transition: "background 0.3s ease",
      "@media (max-width: 40em)": {
        width: "80%",
      },
      "&:hover": {
        background: "#5C2896",
      },
    },
    accordion: {
      boxShadow: "none",
      "&.Mui-expanded": {
        margin: "0px",
      },
    },
    iconoExpand: {
      color: "#4B4B4B",
      width: "2em",
      height: "1.4em",
    },
    inputField: {
      "@media (max-width: 1250px)": {
        width: "90%",
      },
    },
    accordionDetails: {
      padding: "0px",
    },
  }));

  const classesMiniCart = MCCStyles();

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    // Define las rutas que consideras parte del checkout
    const cartPaths = ["/cart"];
    const isCartRoute = cartPaths.some((path) => location.pathname.includes(path));

    dispatch(setCartView(isCartRoute));

    // Agrega una limpieza si es necesario para resetear el estado al salir de la página
    return () => {
      dispatch(setCartView(false));
    };
  }, [location.pathname, dispatch]);

  const isCartView = useSelector((state: RootState) => state.cart.isCartView);

  console.log("milk isCartView", isCartView);

  return (
    <StyledGrid className={classesMiniCart.MainContainer}>
      {!isB2B ? (
        <div className={classesMiniCart.headerContainer}>
          <StyledTypography className={classesMiniCart.headerText}>{title}</StyledTypography>
          <IconButton className={classesMiniCart.closeButton} style={{ padding: "0px" }} onClick={handleCloseDrawer}>
            <CloseIcon />
          </IconButton>
        </div>
      ) : (
        <>
          <StyledTypography
            variant="h6"
            style={{ overflowWrap: "break-word" }}
            className="horizontal-padding-2 top-padding-2">
            {title}
          </StyledTypography>
          <StyledTypography variant="body1" className="horizontal-padding-2 bottom-padding-2">
            {orderType}
          </StyledTypography>
        </>
      )}
      {orderItems.length > 0 ? (
        <>
          <div className={classesMiniCart.orderItemTableContainer}>
            <OrderItemTable
              data={orderItems}
              preShip={true}
              miniCartView={true}
              showPriceLabel={false}
              showDeleteButton={true}
              handleMiniCartClose={handleCloseDrawer}
            />
          </div>
          <div className={classesMiniCart.downPartContainer}>
            <div className={classesMiniCart.miniCartContainer}>
              <Divider />

              <StyledGrid container style={{ marginBottom: "8px" }}>
                <StyledGrid item xs={4} md={4}>
                  <StyledTypography variant="body1" className={classesMiniCart.subtotalesText}>
                    {subtotalLabel}
                  </StyledTypography>
                  <StyledTypography className={classesMiniCart.subtotalesText}>{shipping}</StyledTypography>
                </StyledGrid>

                <StyledGrid item xs={8} md={8}>
                  <StyledTypography variant="body1" align="right" className={classesMiniCart.subtotalesText}>
                    <FormattedPriceDisplay min={subtotal} currency={subtotalCurrency} />
                  </StyledTypography>

                  <StyledTypography variant="body1" align="right" className={classesMiniCart.subtotalesText}>
                    {subtotal > 999 ? (
                      <p
                        style={{
                          color: "#22AD58",
                          fontWeight: "bold",
                          font: "normal normal bold  Rubik",
                          marginTop: "-3px",
                        }}>
                        GRATIS
                      </p>
                    ) : (
                      <FormattedPriceDisplay min={cart.totalShippingCharge} currency={subtotalCurrency} />
                    )}
                  </StyledTypography>
                </StyledGrid>
              </StyledGrid>

              <Divider />
              <Accordion className={classesMiniCart.accordion}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classesMiniCart.iconoExpand} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  onClick={togglePromoCodeVisibility}
                  style={{
                    textAlign: "left",
                    justifyContent: "space-between",
                    width: "100%",
                    display: "flex",
                    backgroundColor: "transparent",
                    font: "normal normal bold 15px/18px Rubik",
                    letterSpacing: "0px",
                    color: "#4B4B4B",
                    borderRadius: "0px",
                    border: "none",
                    padding: "0px",
                    marginBottom: "0px",
                    minHeight: "48px",
                  }}>
                  <StyledTypography
                    component="span"
                    style={{ font: "normal normal bold 15px/18px Rubik", letterSpacing: "0px", color: "#4B4B4B" }}>
                    {t("MiniCart.PromoAsk")}
                  </StyledTypography>
                </AccordionSummary>
                <AccordionDetails className={classesMiniCart.accordionDetails}>
                  <StyledGrid marginBottom="10px">
                    <StyledPaper
                      className={classesMiniCart.promoContainer}
                      style={{ boxShadow: "none", borderRadius: "0px", margin: "0px" }}>
                      <StyledGrid container alignItems="center">
                        <StyledGrid item xs={8} sm={8}>
                          <StyledTextField
                            fullWidth
                            type="text"
                            size="small"
                            value={promoCode}
                            placeholder="Código promocional"
                            className={classesMiniCart.inputField}
                            id={`cart_input_promocode`}
                            onFocus={() => resetPromoCodeError()}
                            onChange={(event) => onPromoCodeChange(event)}
                            onKeyDown={(keyPress) => applyPromoCodeBasedOnKey(keyPress)}
                            error={promoCodeError}
                          />
                        </StyledGrid>
                        <StyledGrid item xs={4} sm={3}>
                          <StyledButton
                            className={classesMiniCart.applyButton}
                            color="primary"
                            disabled={!promoCode}
                            testId="cart-apply-promo-code"
                            onClick={(event) => applyPromoCode(event)}
                            id={`cart_link_2_promocode`}>
                            {t("Cart.Actions.Apply")}
                          </StyledButton>
                        </StyledGrid>
                      </StyledGrid>
                    </StyledPaper>
                  </StyledGrid>
                </AccordionDetails>
              </Accordion>

              {/* <Divider />

              <StyledGrid container className={classesMiniCart.subtotalesText}>
                <Button className={classesMiniCart.buttonPromo} onClick={handleButtonPromo}>
                  <StyledTypography variant="body1" className={classesMiniCart.codigoPromocional}>
                    {promoAsk}
                  </StyledTypography>
                  {showPromoInput ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Button>
                {showPromoInput && (
                  <StyledGrid className={classesMiniCart.promoContainer}>
                    <StyledTextField
                      fullWidth
                      type="text"
                      size="small"
                      value={promoCode}
                      className={classesMiniCart.TextFieldPromo}
                      placeholder={t("Cart.Actions.PromotionalCode")}
                      id={`miniCart_input_promocode`}
                      onFocus={() => resetPromoCodeError()}
                      onChange={(event: any) => onPromoCodeChange(event)}
                      onKeyDown={(keyPress: any) => applyPromoCodeBasedOnKey(keyPress)}
                      error={promoCodeError}
                    />
                    <StyledButton
                      className={classesMiniCart.applyButton}
                      color="primary"
                      disabled={!promoCode}
                      testId="cart-apply-promo-code"
                      onClick={(event: any) => applyPromoCode(event)}
                      id={`miniCart_link_2_promocode`}>
                      {t("Cart.Actions.Apply")}
                    </StyledButton>
                  </StyledGrid>
                )}
              </StyledGrid> */}

              <Divider />

              <StyledGrid container className={classesMiniCart.subtotalesText} style={{ marginBottom: "4px" }}>
                <StyledGrid item xs={7}>
                  <StyledTypography variant="body1" className={classesMiniCart.totales}>
                    {total}
                  </StyledTypography>
                  <StyledTypography variant="body1" className={classesMiniCart.taxes}>
                    {taxes}
                  </StyledTypography>
                </StyledGrid>
                <StyledGrid item xs={5}>
                  <StyledTypography variant="body1" align="right" className={classesMiniCart.totales}>
                    <FormattedPriceDisplay min={cart.grandTotal} currency={subtotalCurrency} />
                  </StyledTypography>
                </StyledGrid>
              </StyledGrid>
            </div>

            <StyledBox className={classesMiniCart.buttonsContainer} textAlign="center">
              {isB2B && !isOrderOwner ? (
                <StyledButton testId="handle-cart-on-click" color="secondary" onClick={handleCartOnClick}>
                  {cartLinkLabel}
                </StyledButton>
              ) : (
                <>
                  <StyledButton
                    className={classesMiniCart.pagarButton}
                    testId="handle-checkout-on-click"
                    color="primary"
                    disabled={!orderItems || orderItems.length <= 0}
                    onClick={handleCheckoutOnClick}>
                    {checkoutLinkLabel}
                  </StyledButton>
                  {isCartView ? (
                    <></>
                  ) : (
                    <StyledButton
                      className={classesMiniCart.bolsaButton}
                      color="secondary"
                      testId="handle-cart-on-click"
                      onClick={handleCartOnClick}>
                      {cartLinkLabel}
                    </StyledButton>
                  )}
                </>
              )}
            </StyledBox>
          </div>
        </>
      ) : (
        <StyledGrid className={classesMiniCart.emptyContainer}>
          <StyledTypography align="center" className={classesMiniCart.emptyCart}>
            <LocalMallOutlinedIcon fontSize="large" />
            {emptyCartMsg}
          </StyledTypography>
          <Link className={classesMiniCart.seguirComprandoButton} color="primary" to={"/"}>
            {emptyCartButton}
          </Link>
        </StyledGrid>
      )}
    </StyledGrid>
  );
});

export default StyledMiniCartContent;

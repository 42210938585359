/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *---------------------------------------------------
 */
//Standard libraries
import { useMemo, useRef, useState } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
//Custom libraries
import styled from "@mui/styled-engine-sc";
import { makeStyles } from "@mui/styles";
import { StyledBox } from "../box";
import { StyledCard } from "../card";
import { StyledCheckbox } from "../check-box";
import { StyledProgressPlaceholder } from "../circular-progress";
import { StyledFormControlLabel } from "../form";
import { StyledLink } from "../link";
import { StyledTypography } from "../typography";
const productCardStyles = makeStyles((theme) => ({
  productCard: {
    backgroundColor: "#F7F7F7",
    border: "none",
    boxShadow: "none",
  },
  center: {
    textAlign: "center",
  },
  imageProductCard: {
    maxWidth: "100%",
    maxHeight: "inherit",
    objectFit: "cover",
    height: "270px",
  },
}));
const StyledProductCard = styled(({ className, ...props }) =>
  props.to ? <StyledLink className={className} {...props} /> : <StyledBox className={className} {...props} />
)`
  ${({ theme }) => `
    display: block;

    >.product-card.w-compare-box {
      height: calc(100% + 2.625rem);
    }

    &.product-card {
      flex: 1;
    }

    &.product-grid {
      .MuiCard-root {
        height: 100%;
      }
    }

  `}
`;

const FlexWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  > label {
    > span.compare-checkbox {
      justify-content: flex-start;
    }
  }
`;

function OverlayMenuItem(props) {
  // const handler = useContext(ProductContext);
  const [productCode, setproductCode] = useState("");
  const [action, setAction] = useState(0);
  const callbackToChild = useRef<any>(null);
  const img = props?.img;
  const openProductCardWidged = (partNumber) => {
    if (props.isTop) {
      if (partNumber !== productCode) {
        setproductCode(partNumber);
        setAction(action + 1);
      } else {
        if (callbackToChild !== null) {
          callbackToChild.current();
        }
      }
    } else {
      // handler(partNumber);
    }
  };
  return (
    <>
      <div className={props.className}>
        {img ? (
          <img src={img} />
        ) : props.noLink ? (
          <div onClick={() => openProductCardWidged(props.partNumber)}> {props.title}</div>
        ) : (
          <a href={props.seoUrl}>{props.title}</a>
        )}
      </div>
      {/* <QuickInfoDetailsWidget
        productNumber={productCode}
        displayQuickInfo={action}
        callbackToParent={callbackToChild}
      /> */}
    </>
  );
}

const StyledOverlayMenuItem = styled(({ ...props }) => <OverlayMenuItem {...props} />)`
    background:rgba(0,0,0,0.6);
    font-weight 300;

    &:hover{
      background:rgba(0,0,0,1);
    }

  `;

function ProductCardNew(props) {
  let countofert = 0;
  const validateRebbonsType = (content, identifier, counterOfert = 1) => {
    if (content === 1) {
      switch (identifier) {
        case "DPD_Oferta":
          return "onlyDpdOferta";
        case "Nuevo":
          return "onlyProductNew";
        default:
          return "onlyProductDiscount";
      }
    } else {
      if (countofert === 1) {
        switch (identifier) {
          case "Nuevo":
            return "productNewUp";
          case "DPD_Oferta":
            return "dpdOfertaUp";
          default:
            return "ribbonDefaultUp";
        }
      } else {
        switch (identifier) {
          case "Nuevo":
            return "productNewDown";
          case "DPD_Oferta":
            return "dpdOfertaDown";
          default:
            return "ribbonDefaultDown";
        }
      }
    }
  };
  return (
    <div className={props.className}>
      {props.content.map((attribute: any, index: number) => {
        if (attribute.storeDisplay && countofert < 2) {
          if (props.content.length === 1) {
            return (
              <>
                <StyledOverlayMenuItem
                  key={`ProductCardNew-${index}`}
                  className={validateRebbonsType(props.content.length, attribute.identifier, countofert)}
                  noLink
                  title={props.ofer && attribute.identifier !== "Nuevo" ? attribute.values[0]["value"] : attribute.name}
                />
              </>
            );
          } else {
            countofert++;
            return (
              <StyledOverlayMenuItem
                key={`ProductCardNew-${index}`}
                className={validateRebbonsType(props.content.length, attribute.identifier, countofert)}
                noLink
                title={props.ofer && attribute.identifier !== "Nuevo" ? attribute.values[0]["value"] : attribute.name}
              />
            );
          }
        } else {
          return null;
        }
      })}
    </div>
  );
}

const StyledProductCardNew = styled(({ ...props }) => <ProductCardNew {...props} />)`
  position: absolute;
  top: 30px;
  width: 100%;
  height: 35px;
  font-size: 13px;
  text-align: center;

  a,
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &.productCardOverlay {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .productNewUp {
    height: 50%;
    width: 40%;
    background: rgba(129, 89, 147, 1);
    border-radius: 0px 15px 0px 0px;
  }
  .productNewDown {
    height: 50%;
    width: 40%;
    background: rgba(129, 89, 147, 1);
    border-radius: 0px 0px 15px 0px;
  }
  .ribbonDown {
    height: 50%;
    width: 40%;
    background: green;
    border-radius: 0px 0px 15px 0px;
  }
  .ribbonDefaultUp {
    height: 23px;
    width: 51px;
    background: #272633;
    margin-left: 6px;
    border-radius: 2px;
  }
  .ribbonDefaultDown {
    height: 23px;
    width: 51px;
    background: #272633;
    margin-left: 6px;
    border-radius: 2px;
  }
  .onlyProductNew {
    height: 23px;
    width: 51px;
    background: #272633;
    margin-left: 6px;
    border-radius: 2px;
  }
  .productDiscount1 {
    height: 23px;
    width: 40px;
    background: #bf1c1c;
    margin-left: 6px;
    border-radius: 2px;
  }
  .onlyProductDiscount {
    height: 23px;
    width: 40px;
    background: #bf1c1c;
    margin-left: 6px;
    border-radius: 2px;
  }

  .dpdOfertaUp {
    height: 23px;
    width: 51px;
    background: #272633;
    margin-left: 6px;
    border-radius: 2px;
  }
  .dpdOfertaDown {
    height: 50%;
    width: 40%;
    background: #bf0000;
    border-radius: 0px 0px 15px 0px;
  }

  .onlyDpdOferta {
    height: 80%;
    width: 40%;
    background: #bf0000;
    border-radius: 0px 15px 15px 0px;
  }
  .productDiscount2 {
    height: 23px;
    width: 40px;
    background: #bf1c1c;
    margin-left: 6px;
    border-radius: 2px;
  }

  @media (max-width: 667px) and (orientation: portrait) {
    bottom: calc(-100% + 230px);
    // left: calc( -100% + 164.5px);
  }

  @media (max-width: 667px) and (orientation: landscape) {
    bottom: calc(-100% + 230px);
  }

  @media (min-width: 670px) and (max-width: 1256px) and (orientation: portrait) {
    bottom: calc(-100% + 230px);
    // left: calc( -100% + 194.5px);
  }

  @media (min-width: 670px) and (max-width: 1256px) and (orientation: landscape) {
    bottom: calc(-100% + 245px);
  }
`;

const CompareBox = ({ compare, product, catentryId }) => {
  const { type: t, partNumber } = product;
  // const visibility = t === "product" || t === "item" || t === "variant" ? "visible" : "hidden";
  const visibility = "hidden";
  return (
    <div style={{ marginLeft: "16px", visibility }}>
      <StyledFormControlLabel
        control={
          <StyledCheckbox
            data-testid={`product-compare-${partNumber?.toLowerCase()}-checkbox`}
            className="compare-checkbox"
            disabled={!!(!compare.data.checked[catentryId] && compare.data.disabled)}
            checked={!!compare.data.checked[catentryId]}
            onChange={(e) => compare.onChange(e, catentryId, product)}
          />
        }
        label={<StyledTypography variant="caption">{compare.t(compare.labels.addTo)}</StyledTypography>}
      />
    </div>
  );
};

interface ProductCardProps {
  seoUrl: any;
  name: any;
  ribbonads: any[];
  thumbnail: any;
  thumbnailLoading?: boolean;
  price?: number | null;
  swatches: any[];
  catentryId?: string;
  onClick?: any;
  actions?: any;
  className?: any;
  categoryId?: string;
  formattedPriceDisplay: any;
  link?: any;
  compare?: any;
  product?: any;
  isB2B: boolean;
  productAttributes?: any;
}

/**
 * Product Card component
 * displays catentry image, name, price, etc
 * @param props
 */
function ProductCard(props: ProductCardProps) {
  const {
    seoUrl,
    name,
    ribbonads,
    thumbnail,
    categoryId = "",
    thumbnailLoading = false,
    price = null,
    swatches,
    onClick,
    catentryId = "",
    formattedPriceDisplay,
    actions: cardActions = null,
    link = null,
    compare,
    product,
    productAttributes,
  } = props;
  const classesProductCard = productCardStyles();
  const to = useMemo(() => link?.pathname ?? seoUrl, [link, seoUrl]);
  const linkState = useMemo(() => link?.state ?? { categoryId }, [link, categoryId]);
  const onClickAction = useMemo(() => (onClick ? { onClick } : {}), [onClick]);

  const atributosValidos = [] as any;
  const productoGanador = [] as any;
  const tallasDisponibles = [] as any;

  productAttributes &&
    productAttributes.map((attribute: any, index: number) => {
      if (attribute.storeDisplay === "true") {
        if (!attribute.name.includes("DPT_") && !attribute.name.includes("DPS_")) {
          atributosValidos.push(attribute);
        }
      } else if (attribute.name === "DPD_Producto Ganador") {
        productoGanador.push(attribute);
      } else if (attribute.identifier === "DPD_TallasDisponibles") {
        console.log(attribute.name, "tallas disponibels");
        tallasDisponibles.push(attribute);
      }
    });

  const contentComponent = (
    <>
      {thumbnailLoading ? (
        <StyledProgressPlaceholder className="vertical-padding-8" />
      ) : (
        <LazyLoadComponent visibleByDefault={(window as any).__isPrerender__ || false}>
          <div className={classesProductCard.center}>
            <img src={thumbnail} alt={name} className={classesProductCard.imageProductCard} />
          </div>
          {/* <StyledCardMedia image={thumbnail} title={name} component="div"></StyledCardMedia> */}
          <StyledProductCardNew ofer={true} content={atributosValidos} />
        </LazyLoadComponent>
      )}
      <StyledTypography variant="body2" align="left" className="bottom-margin-1 wrapText">
        {name}
      </StyledTypography>
      {/* {swatches.length > 0 && <StyledTypography align="center">{swatches}</StyledTypography>} */}
      {price && (
        <StyledTypography variant="subtitle3" align="left">
          {formattedPriceDisplay}
        </StyledTypography>
      )}
    </>
  );

  const compareBox = useMemo(
    () => (compare ? <CompareBox {...{ compare, product, catentryId }} /> : <></>),
    [compare, product, catentryId]
  );

  return (
    <FlexWrapper>
      {/* {ribbonads.map(({ identifier, value }: any, i) => (
        <RibbonAd {...{ identifier, value, idx: i, key: i }} />
      ))} */}
      <StyledProductCard
        className="product-card"
        to={to}
        state={linkState}
        id={catentryId ? `productCard_a_1_${catentryId}` : ""}
        {...onClickAction}>
        <StyledCard
          testId={catentryId}
          {...{
            className: `product-card ${compare ? "w-compare-box" : ""} ${classesProductCard.productCard}`,
            contentComponent,
            cardActions,
          }}
        />
      </StyledProductCard>
      {compareBox}
    </FlexWrapper>
  );
}
export default ProductCard;

/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
import styled from "@mui/styled-engine-sc";
import { useTheme } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Scrollbar, A11y } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const StyledSlideContainer = styled("div")`
  ${({ theme }) => `

  .swiper-slide img {
    width: 100%;
    height: auto;
    object-fit: cover;
    borderRadius: 0;
    background: transparent;
  }
  `}
`;

const StyledContentCarouselSlider = ({ slidesList }: any) => {
  return (
    <StyledSlideContainer>
      <Swiper modules={[Navigation, A11y]} slidesPerView={1}>
        {slidesList.map((slideContent, index) => (
          <SwiperSlide key={index} className="swiper-slide">
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>
    </StyledSlideContainer>
  );
};
export { StyledContentCarouselSlider };

/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//Custom libraries
import MiniCartPopperContent from "./MiniCartPopperContent";
import { MCW_NUMBER, ZERO_NUMBER } from "../../constants/common";
//Redux
import { numItemsSelector } from "../../redux/selectors/order";
//UI
import {
  StyledAccountPopper,
  StyledButton,
  StyledTypography,
  StyledHeaderActions,
} from "@hcl-commerce-store-sdk/react-component";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import { makeStyles } from "@mui/styles";
import { openDrawer, closeDrawer } from "../../redux/reducers/drawer";
import { RootReducerState } from "../../redux/reducers/index";
interface MiniCartProps {
  id: string;
  open: boolean;
  handleClick: (e?: any) => void;
  handleClose: (e?: any) => void;
}

const headerCartItemsStyle = makeStyles((theme) => ({
  itemsCart: {
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    gap: "6px",
  },
}));

/**
 * MiniCart component
 * displays number of cart items icon
 * @param props
 */
const MiniCart = React.forwardRef<HTMLButtonElement | null, MiniCartProps>((props: any, ref: any) => {
  const { id, open, handleClick, handleClose } = props;
  const { t } = useTranslation();
  const numItems = useSelector(numItemsSelector);
  const [arrowRef, setArrowRef] = useState<any>(null);
  const stylesCustom = headerCartItemsStyle();
  const dispatch = useDispatch();

  const isOpen = useSelector((state: RootReducerState) => state.drawer.isOpen);

  const handleOpenDrawer = () => {
    handleClick();
    dispatch(openDrawer());
  };

  const handleCloseDrawer = () => {
    dispatch(closeDrawer());
  };

  /*   const [drawerOpen, setDrawerOpen] = useState(false);

  const handleOpenDrawer = () => {
    handleClick();
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  }; */

  const calcOffset = () => {
    const mcw = MCW_NUMBER;
    const left = ref?.current?.offsetLeft;
    const rc = left < mcw ? mcw - left : ZERO_NUMBER;
    return [rc, ZERO_NUMBER];
  };
  const offset = calcOffset();

  return (
    <>
      <StyledButton
        testId="header-mini-cart-button"
        ref={ref}
        className="header-actionsButton"
        variant="text"
        color="secondary"
        onClick={handleOpenDrawer}>
        <StyledHeaderActions className={stylesCustom.itemsCart}>
          <LocalMallOutlinedIcon />
          {/* {numItems} */}
          <StyledTypography variant="h5">{numItems}</StyledTypography>
        </StyledHeaderActions>
      </StyledButton>

      <StyledAccountPopper
        id={id}
        open={open}
        anchorEl={ref?.current}
        onClose={handleClose}
        placement="bottom"
        modifiers={[
          {
            name: "offset",
            options: {
              offset,
            },
          },
          { name: "flip", enabled: false },
          { name: "preventOverflow", enabled: false },
          { name: "hide", enabled: false },
          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
        className="mini-cart-popper">
        <span className="arrow" ref={setArrowRef} />
        <MiniCartPopperContent
          drawerOpen={isOpen}
          handleClose={handleClose}
          handleOpenDrawer={handleOpenDrawer}
          handleCloseDrawer={handleCloseDrawer}
        />
      </StyledAccountPopper>
    </>
  );
});

export default MiniCart;

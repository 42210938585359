import React from "react";
import styled from "@mui/styled-engine-sc";
import { StyledTypography } from "../typography";
import { StyledPaper } from "../paper";
import { StyledLink } from "../link";
import { StyledTooltip } from "../tooltip";

const StyledAccountLink = styled(({ ...props }) => <StyledLink {...props} />)`
  ${({ theme }) => `
    color: ${theme.palette.text.primary};
    display: block;
    margin-left: 64px;

    @media (max-width: 1220px) {
      margin-left: 20px;
    }

    @media (max-width: 960px) {
      margin-left:0px;
    }

    &:hover {
      h6, p {
        color: ${theme.palette.primary.dark};
      }
    }
  `}
`;

const StyledLinkBoxWrapper = styled(({ ...props }) => (
  <StyledPaper style={{ marginBottom: "0px !important" }} {...props} />
))`
  ${({ theme }) => `
  display: flex;
  align-items: center;
  padding: 0px;
  text-align: left;
  font: normal normal medium 16px/19px Rubik;
  letter-spacing: 0px;
  color: #272633;
  border: 0px;
  border-radius: 6px;
  box-shadow: none;
  opacity: 1;
  margin-bottom: 0px !important;

  &.disabled {
    h6, p {
      color: ${theme.palette.text.disabled};
    }

    .MuiSvgIcon-root {
      color: ${theme.palette.text.disabled};
    }
  }
  `}
`;

const StyledLinkIcon = styled("div")`
  ${({ theme }) => `
    position: relative;
    flex: none;
/*     background-color: ${theme.palette.action.hover}; */
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-right: ${theme.spacing(2)}; */

    .MuiSvgIcon-root {
      color: ${theme.palette.primary.main};
    }
  `}
`;

const TextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

interface LinkBoxProps {
  title: any;
  disabledTitle: string;
  description: string;
  url: string;
  icon: any;
  isActive:boolean;
  disabled?: boolean;
  
}

export function StyledLinkBox({ title, disabledTitle, description, disabled, icon, url, isActive }: LinkBoxProps) {
  const LinkTextElements = (
    <TextContainer>
      <StyledTypography variant="subtitle1">{title}</StyledTypography>
      <StyledTypography variant="body1">{description}</StyledTypography>
    </TextContainer>
  );

  const LinkContent = (
    <StyledLinkBoxWrapper style={isActive?{border:"1px solid #5C2797"}:{border:"1px solid #fff"}}  className={disabled ? "disabled" : ""}>
      <StyledLinkIcon>{icon}</StyledLinkIcon>
      {LinkTextElements}
    </StyledLinkBoxWrapper>
  );

  return disabled ? (
    <StyledTooltip title={disabledTitle}>
      <div>{LinkContent}</div>
    </StyledTooltip>
  ) : (
    <StyledAccountLink to={url} title={description}>
      {LinkContent}
    </StyledAccountLink>
  );
}

/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useCallback, useState, useEffect, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { paramCase } from "change-case";
//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
//Custom libraries
import { footerConfig } from "./footerConstant";
import ContentRecommendationWidget from "../commerce-widgets/content-recommendation-widget";
import { SessionErrorDialog } from "../widgets/session-error-modal";
import ConfirmationDialog from "../widgets/confirmation-dialog/ConfirmationDialog";
import { Accordion, AccordionDetails, AccordionSummary, InputAdornment, useMediaQuery } from "@mui/material";
import { RootState } from "../../redux/store/index";
import { useSelector } from "react-redux";
//UI
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import IconButton from "@mui/material/IconButton";

import { ReactComponent as IconFacebookSVG } from "../../assets/eaas-b2c-store/images/footer/facebook.svg";
import { ReactComponent as IconInstagramSVG } from "../../assets/eaas-b2c-store/images/footer/instagram.svg";
import { ReactComponent as IconTiktokSVG } from "../../assets/eaas-b2c-store/images/footer/tiktok.svg";
import { ReactComponent as IconXSVG } from "../../assets/eaas-b2c-store/images/footer/x.svg";
import { ReactComponent as IconYoutubeSVG } from "../../assets/eaas-b2c-store/images/footer/youtube.svg";

import { ReactComponent as IconPayPalSVG } from "../../assets/eaas-b2c-store/images/footer/paypal.svg";
import { ReactComponent as IconAmericanExpressSVG } from "../../assets/eaas-b2c-store/images/footer/americanExpress.svg";
import { ReactComponent as IconVisaSVG } from "../../assets/eaas-b2c-store/images/footer/visa.svg";
import { ReactComponent as IconMasterCardSVG } from "../../assets/eaas-b2c-store/images/footer/mastercard.svg";

import { Hidden } from "@mui/material";

import Marquee from "react-fast-marquee";

import MailchimpSubscribe from "react-mailchimp-subscribe";

import {
  StyledTextField,
  StyledGrid,
  StyledContainer,
  StyledTypography,
  StyledFooter,
  StyledTextFieldFooter,
} from "../../elements";
import { makeStyles } from "@mui/styles";
import "./../../../src/animate.css";
import "../custom/header/eaas.css";
import EspotMzFC from "components/custom/eSpot/EspotMzFC";
import theme from "themes/emerald-theme";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const footerStyles = makeStyles((theme) => ({
  footerContainer: {
    maxWidth: "100%",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  footerSlider: {
    borderBottom: "1px solid #FFFFFF",
    paddingBottom: "21px",
    paddingTop: "5px",
  },
  linkStyle: {
    color: theme.palette.info.contrastText,
  },
  textFieldEmail: {
    "& .MuiInputBase-root": {
      borderRadius: "5px",
      width: "100vw",
      height: "53px",
      background: "#3C454B 0% 0% no-repeat padding-box",
      "@media (min-width: 600px)": {
        borderRadius: "0px",
        width: "388px",
      },
    },
    "& .MuiOutlinedInput-input": {
      color: "white",
    },
  },
  containerLogos: {
    width: "max-content",
  },
  logoFirst: {
    width: "max-content",
    marginRight: "20px",
    "& a > img": {
      height: "44px !important",
    },
  },
  logoShop: {
    width: "max-content",
    "& a > img": {
      height: "32px !important",
    },
  },
  logoArrebatando: {
    "& a > img": {
      height: "32px !important",
    },
  },
  logosContainer: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "end",
      marginRight: "20px",
    },
  },
  containerNavTi: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  containerNavIcon: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "end",
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  iconArrowCont: {
    fontSize: "2.1rem",
  },
  containerCopyRight: {
    [theme.breakpoints.up("sm")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "contents",
    },
  },
  footerCopyRight: {
    [theme.breakpoints.down("sm")]: {
      borderTop: "0.5px solid rgb(135, 140, 143)",
      paddingTop: "15px",
      flexWrap: "wrap",
      marginLeft: "12px",
      marginRight: "12px",
      width: "100%",
    },
  },
  footerIconSocial: {
    [theme.breakpoints.down("sm")]: {
      width: "25px",
      height: "25px",
    },
  },
  containerfooterIconSocial: {
    [theme.breakpoints.down("sm")]: {
      marginRight: "13px !important",
    },
  },
  linkFooter: {
    marginBottom: "4px",
  },
  capTextEmail: {
    fontSize: "0.75rem",
  },
  navTitleLink: {
    width: "100%",
  },
  iconEmailSend: {
    marginBottom: "8px",
  },
  containerLabelFooter: {
    marginRight: "20px",
  },
  containerLabelFooterCenter: {
    width: "50%",
  },
  sloganLabel: {
    marginRight: "30px",
  },
  marqueeContent: {
    display: "flex",
    listStyle: "none",
    animation: "scrollingSlogan 20s linear infinite",
    width: "400px",
  },
  marqueeItem: {
    flexShrink: 0,
  },
  accordion: {
    background: "transparent",
    boxShadow: "none",
    margin: "0px !important",
    color: "white",
  },
  accordionSummary: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px",
    margin: "0px !important",
    minHeight: "20px !important",
    "& .Mui-expanded": {
      margin: "0px !important",
    },
    "& .MuiAccordionSummary-content": {
      padding: "0px",
      margin: "0px",
    },
  },
  accordionDetails: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
  },
  linksFooter: {
    "&:hover": {
      textDecoration: "underline !important",
    },
  },
}));

function Footer(props: any) {
  const classesFooter = footerStyles();
  const { t } = useTranslation();
  const { mySite } = useSite();
  const [logonInputProps, setLogonInputProps] = useState<any>({});
  const [quantityItemsSlogan, setQuantityItemsSlogan] = useState<any>(0);
  const [anchoPantalla, setAnchoPantalla] = useState(window.innerWidth);
  const marqueeTraduction = t("Footer.Marquee");
  const parts = marqueeTraduction.split(/(•|\s+)/);
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    function manejarCambioAncho() {
      setAnchoPantalla(window.innerWidth);
    }

    window.addEventListener("resize", manejarCambioAncho);

    return () => {
      window.removeEventListener("resize", manejarCambioAncho);
    };
  }, []);

  useEffect(() => {
    const itemsContainer = Math.trunc(anchoPantalla / 400);
    setQuantityItemsSlogan(itemsContainer);
  }, [anchoPantalla]);

  useEffect(() => {
    if (mySite) {
      if (mySite.isB2B) {
        setLogonInputProps({
          maxLength: 100,
          type: "text",
        });
      } else {
        setLogonInputProps({
          maxLength: 100,
          type: "email",
          placeholder: "Correo electrónico",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite]);

  const LogoFirst = useCallback(() => {
    return mySite != null ? <EspotMzFC espotName="FooterStoreLogo" /> : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, mySite]);

  const LogoShop = useCallback(() => {
    return mySite != null ? (
      <ContentRecommendationWidget
        {...{
          widget: {
            id: `footer-${paramCase(footerConfig.espot.eSpotNameMazaShop)}`,
            widgetName: "content-recommendation-widget",
            name: footerConfig.espot.eSpotNameMazaShop,
            properties: {
              emsName: footerConfig.espot.eSpotNameMazaShop,
            },
          },
          page: { name: "" },
        }}></ContentRecommendationWidget>
    ) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, mySite]);

  const LogoArrebatando = useCallback(() => {
    return mySite != null ? (
      <ContentRecommendationWidget
        {...{
          widget: {
            id: `footer-${paramCase(footerConfig.espot.eSpotNameArrebatando)}`,
            widgetName: "content-recommendation-widget",
            name: footerConfig.espot.eSpotNameArrebatando,
            properties: {
              emsName: footerConfig.espot.eSpotNameArrebatando,
            },
          },
          page: { name: "" },
        }}></ContentRecommendationWidget>
    ) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, mySite]);

  const Logos = useCallback(() => {
    return mySite != null ? (
      <div className="footer-logo">
        <div className={classesFooter.logoFirst}>
          <LogoFirst />
        </div>
        <div className={classesFooter.logoShop}>
          <LogoShop />
        </div>
      </div>
    ) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, mySite]);

  const url =
    "https://solemti.us17.list-manage.com/subscribe/post?u=cadfe48ae7396cfa526740a25&amp;id=cd646c12c8&amp;f_id=009bc2e1f0";

  const SimpleForm: React.FC = () => {
    const [email, setEmail] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [status, setStatus] = useState<string | null>(null);

    const validateEmail = (email: string): boolean => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    };

    const handleSubscribe = (subscribe: (data: { EMAIL: string }) => void) => {
      if (validateEmail(email)) {
        subscribe({ EMAIL: email });
      } else {
        setError("Please enter a valid email address.");
      }
    };

    useEffect(() => {
      if (status === "success") {
        setEmail("");
      }
      if (status === "success" || status === "error" /* || status === "sending" */) {
        setError("");
      }
    }, [status]);

    return (
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status: mailchimpStatus, message }) => {
          if (mailchimpStatus !== status) {
            setStatus(mailchimpStatus);
          }

          return (
            <>
              <StyledTextFieldFooter
                isInputFooter={true}
                className={classesFooter.textFieldEmail}
                variant="outlined"
                margin="normal"
                name="email"
                value={email}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                placeholder="Enter your email"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        className={classesFooter.iconEmailSend}
                        aria-label="send email"
                        edge="end"
                        onClick={() => handleSubscribe(subscribe)}>
                        <SendOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {error && <div style={{ color: "red" }}>{error}</div>}
              {/* {status === "sending" && <><div>Sending...</div></>} */}
              {status === "error" && <div style={{ color: "red" }} dangerouslySetInnerHTML={{ __html: message }} />}
              {status === "success" && <div style={{ color: "#009dc5" }}>Thank you for subscribing!</div>}
            </>
          );
        }}
      />
    );
  };

  const SectionCustom = () => {
    return (
      <div className="footer-nav2">
        <StyledTypography variant="titleFooter">{t("Footer.CustomSection.Label")}</StyledTypography>
        <div>
          <SimpleForm />
          {/*           <StyledTextField
            className={classesFooter.textFieldEmail}
            variant="outlined"
            margin="normal"
            name="email"
            inputProps={logonInputProps}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className={classesFooter.iconEmailSend}
                    aria-label="toggle password visibility"
                    edge="end">
                    <SendOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          /> */}
        </div>
        <div>
          <StyledTypography variant="caption">
            <span className={classesFooter.capTextEmail}>
              {" "}
              {t("Footer.CustomSection.TermsConditionsPrivacyNotice")}
            </span>
          </StyledTypography>
        </div>
        <Hidden mdDown>
          <Logos />
          <div className="footer-logo">
            <div className={classesFooter.logoArrebatando}>
              <LogoArrebatando />
            </div>
          </div>
          <div>
            <div className={`footer-social-link ${classesFooter.containerfooterIconSocial}`}>
              <a href="https://www.facebook.com/MazatlanFC/" target="_blank">
                <IconFacebookSVG className={classesFooter.footerIconSocial} />
              </a>
            </div>
            <div className={`footer-social-link ${classesFooter.containerfooterIconSocial}`}>
              <a href="https://www.instagram.com/mazatlanfc/" target="_blank">
                <IconInstagramSVG className={classesFooter.footerIconSocial} />
              </a>
            </div>
            <div className={`footer-social-link ${classesFooter.containerfooterIconSocial}`}>
              <a href="https://www.tiktok.com/@mazatlanfc" target="_blank">
                <IconTiktokSVG className={classesFooter.footerIconSocial} />
              </a>
            </div>
            <div className={`footer-social-link ${classesFooter.containerfooterIconSocial}`}>
              <a href="https://x.com/MazatlanFC" target="_blank">
                <IconXSVG className={classesFooter.footerIconSocial} />
              </a>
            </div>
            <div className={`footer-social-link ${classesFooter.containerfooterIconSocial}`}>
              <a href="https://www.youtube.com/@MazatlanFC" target="_blank">
                <IconYoutubeSVG className={classesFooter.footerIconSocial} />
              </a>
            </div>
          </div>
        </Hidden>
      </div>
    );
  };

  const SecurityOnlineServiceLinks = () => {
    const { t } = useTranslation();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [expanded, setExpanded] = useState(!isSmallScreen);

    useEffect(() => {
      setExpanded(!isSmallScreen);
    }, [isSmallScreen]);

    const handleChange = () => {
      if (isSmallScreen) {
        setExpanded(!expanded);
      }
    };

    return isSmallScreen ? (
      <div className="footer-nav" style={{ paddingTop: "8px" }}>
        <Accordion className={classesFooter.accordion} expanded={expanded} onChange={handleChange}>
          <AccordionSummary
            className={classesFooter.accordionSummary}
            expandIcon={isSmallScreen ? <ExpandMoreIcon /> : null}>
            <StyledTypography className={`navInfo ${classesFooter.containerNavTi}`} variant="titleFooter">
              <div className={classesFooter.navTitleLink}>{t("Footer.SecurityOnline.Label")}</div>
            </StyledTypography>
          </AccordionSummary>
          <AccordionDetails className={classesFooter.accordionDetails}>
            <StyledTypography>
              <EspotMzFC espotName="SecurityOnline" />
            </StyledTypography>
          </AccordionDetails>
        </Accordion>
      </div>
    ) : (
      <div className="footer-nav">
        <div className={classesFooter.accordion}>
          <StyledTypography className={`navInfo ${classesFooter.containerNavTi}`} variant="titleFooter">
            <div className={classesFooter.navTitleLink}>{t("Footer.SecurityOnline.Label")}</div>
          </StyledTypography>
          <div className={classesFooter.accordionDetails}>
            <StyledTypography>
              <EspotMzFC espotName="SecurityOnline" />
            </StyledTypography>
          </div>
        </div>
      </div>
    );
  };

  const CustomerSupportLinks = () => {
    const { t } = useTranslation();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [expanded, setExpanded] = useState(!isSmallScreen);

    useEffect(() => {
      setExpanded(!isSmallScreen);
    }, [isSmallScreen]);

    const handleChange = () => {
      if (isSmallScreen) {
        setExpanded(!expanded);
      }
    };

    return isSmallScreen ? (
      <div className="footer-nav" style={{ paddingTop: "8px" }}>
        <Accordion className={classesFooter.accordion} expanded={expanded} onChange={handleChange}>
          <AccordionSummary
            className={classesFooter.accordionSummary}
            expandIcon={isSmallScreen ? <ExpandMoreIcon /> : null}>
            <StyledTypography className={`navInfo ${classesFooter.containerNavTi}`} variant="titleFooter">
              <div className={classesFooter.navTitleLink}>{t("Footer.CustomerSupport.Label")}</div>
            </StyledTypography>
          </AccordionSummary>
          <AccordionDetails className={classesFooter.accordionDetails}>
            <EspotMzFC espotName="CustomerSupport" />
          </AccordionDetails>
        </Accordion>
      </div>
    ) : (
      <div className="footer-nav">
        <div className={classesFooter.accordion}>
          <StyledTypography className={`navInfo ${classesFooter.containerNavTi}`} variant="titleFooter">
            <div className={classesFooter.navTitleLink}>{t("Footer.CustomerSupport.Label")}</div>
          </StyledTypography>
          <div className={classesFooter.accordionDetails}>
            <EspotMzFC espotName="CustomerSupport" />
          </div>
        </div>
      </div>
    );
  };

  const InformationLinks = () => {
    const { t } = useTranslation();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [expanded, setExpanded] = useState(!isSmallScreen);

    useEffect(() => {
      setExpanded(!isSmallScreen);
    }, [isSmallScreen]);

    const handleChange = () => {
      if (isSmallScreen) {
        setExpanded(!expanded);
      }
    };

    return isSmallScreen ? (
      <div className="footer-nav" style={{ paddingTop: "8px" }}>
        <Accordion className={classesFooter.accordion} expanded={expanded} onChange={handleChange}>
          <AccordionSummary
            className={classesFooter.accordionSummary}
            expandIcon={isSmallScreen ? <ExpandMoreIcon /> : null}>
            <StyledTypography className={`navInfo ${classesFooter.containerNavTi}`} variant="titleFooter">
              <div className={classesFooter.navTitleLink}>{t("Footer.Information.Label")}</div>
            </StyledTypography>
          </AccordionSummary>
          <AccordionDetails className={classesFooter.accordionDetails}>
            <EspotMzFC espotName="InformationLinks" />
          </AccordionDetails>
        </Accordion>
      </div>
    ) : (
      <div className="footer-nav">
        <div className={classesFooter.accordion}>
          <StyledTypography className={`navInfo ${classesFooter.containerNavTi}`} variant="titleFooter">
            <div className={classesFooter.navTitleLink}>{t("Footer.Information.Label")}</div>
          </StyledTypography>
          <div className={classesFooter.accordionDetails}>
            <EspotMzFC espotName="InformationLinks" />
          </div>
        </div>
      </div>
    );
  };

  const TextCopyright = () => {
    return (
      <div className={classesFooter.containerLabelFooter}>
        <StyledTypography variant="caption">
          <span> {t("Footer.Copyright")}</span>
        </StyledTypography>
      </div>
    );
  };

  const Copyright = () => {
    return (
      <div className={`copyright ${classesFooter.footerCopyRight}`}>
        {isMediumScreen && <TextCopyright />}

        <div className={classesFooter.containerLabelFooter}>
          <StyledTypography variant="caption">
            {/* <span> {t("Footer.TermsAndConditions")}</span> */}
            {/*<StyledTooltip title={`${t("Footer.DisabledMessage")}`}>*/}
            <Link to="/terminos-condiciones" className={classesFooter.linksFooter}>
              {t("Footer.TermsAndConditions")}
            </Link>
            {/*</StyledTooltip>*/}
          </StyledTypography>
        </div>

        <div className={`${classesFooter.containerLabelFooter} ${classesFooter.containerLabelFooterCenter}`}>
          <StyledTypography variant="caption">
            {/* <span> {t("Footer.PrivacyPolicy")}</span> */}
            <Link to="/privacidad" className={classesFooter.linksFooter}>
              {t("Footer.PrivacyPolicy")}
            </Link>
          </StyledTypography>
        </div>

        {isSmallScreen && <TextCopyright />}
      </div>
    );
  };

  const MethodsPayments = () => {
    return (
      <div className={classesFooter.logosContainer}>
        <div className="footer-payment-icon paypal">
          <IconPayPalSVG />
        </div>
        <div className="footer-payment-icon visa">
          <IconVisaSVG />
        </div>
        <div className="footer-payment-icon master-card">
          <IconMasterCardSVG />
        </div>
        <div className="footer-payment-icon american-express">
          <IconAmericanExpressSVG />
        </div>
      </div>
    );
  };

  const SocialLogos = () => {
    return (
      <Hidden mdUp>
        <div className="footer-nav">
          <MethodsPayments />
          <Logos />
          <div className="footer-logo">
            <div className={classesFooter.logoArrebatando}>
              <LogoArrebatando />
            </div>
          </div>
          <div>
            <div className={`footer-social-link ${classesFooter.containerfooterIconSocial}`}>
              <a href="https://www.facebook.com/MazatlanFC/" target="_blank">
                <IconFacebookSVG className={classesFooter.footerIconSocial} />
              </a>
            </div>
            <div className={`footer-social-link ${classesFooter.containerfooterIconSocial}`}>
              <a href="https://www.instagram.com/mazatlanfc/" target="_blank">
                <IconInstagramSVG className={classesFooter.footerIconSocial} />
              </a>
            </div>
            <div className={`footer-social-link ${classesFooter.containerfooterIconSocial}`}>
              <a href="https://www.tiktok.com/@mazatlanfc" target="_blank">
                <IconTiktokSVG className={classesFooter.footerIconSocial} />
              </a>
            </div>
            <div className={`footer-social-link ${classesFooter.containerfooterIconSocial}`}>
              <a href="https://x.com/MazatlanFC" target="_blank">
                <IconXSVG className={classesFooter.footerIconSocial} />
              </a>
            </div>
            <div className={`footer-social-link ${classesFooter.containerfooterIconSocial}`}>
              <a href="https://www.youtube.com/@MazatlanFC" target="_blank">
                <IconYoutubeSVG className={classesFooter.footerIconSocial} />
              </a>
            </div>
          </div>
        </div>
      </Hidden>
    );
  };

  const isCheckoutView = useSelector((state: RootState) => state.checkout.isCheckoutView);

  const footerStylesAlter = makeStyles((theme) => ({
    /* ALTER FOOTER */
    espotContainer: {
      maxWidth: "350px",
      alignItems: "center",
      display: "flex",
      justifyContent: "flex-start",
      "& img": {
        width: "auto",
        height: "40px",
      },
    },
    linksContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "40px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
      },
    },
    alterFooterContainer: {
      backgroundColor: "#FFFFFF",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px 40px",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    espotCont: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "20px",
      },
    },
  }));

  const classesFooterAlter = footerStylesAlter();

  return (
    <>
      {isCheckoutView ? (
        <StyledFooter style={{ marginTop: "20px" }}>
          <StyledContainer className={classesFooterAlter.alterFooterContainer}>
            <StyledGrid item xs={12} sm={6} className={classesFooterAlter.espotContainer}>
              <EspotMzFC espotName="metodos-pagoP" />
            </StyledGrid>

            <StyledGrid item xs={12} sm={6} className={classesFooterAlter.linksContainer}>
              <EspotMzFC espotName="Devolucion_Envio" />
            </StyledGrid>
          </StyledContainer>
        </StyledFooter>
      ) : (
        <StyledFooter style={{ marginTop: "0px" }}>
          <StyledContainer className={classesFooter.footerContainer}>
            <StyledGrid container className="footer-bottom">
              <StyledGrid item xs={12} sm={12} className={classesFooter.footerSlider}>
                <Marquee>
                  <StyledTypography variant="slogan" className={classesFooter.sloganLabel}>
                    {parts.map((part, index) => (
                      <span
                        key={index}
                        style={{ marginRight: part === "•" ? "1em" : "0", marginLeft: part === "•" ? "1em" : "0" }}>
                        {part}
                      </span>
                    ))}
                  </StyledTypography>
                </Marquee>
              </StyledGrid>
            </StyledGrid>
            <div style={{ display: "flex", justifyContent: "center", flexFlow: "wrap" }}>
              <div style={{ maxWidth: "1440px", width: "100vw" }}>
                <StyledGrid container spacing={1} className="footer-top">
                  <StyledGrid item xs={12} lg={4}>
                    <SectionCustom />
                  </StyledGrid>
                  <StyledGrid item xs={12} sm={4} lg={3}>
                    <SecurityOnlineServiceLinks />
                  </StyledGrid>
                  <StyledGrid item xs={12} sm={4} lg={3}>
                    <CustomerSupportLinks />
                  </StyledGrid>
                  <StyledGrid item xs={12} sm={4} lg={2}>
                    <InformationLinks />
                  </StyledGrid>
                  <StyledGrid item xs={12} lg={4}>
                    <SocialLogos />
                  </StyledGrid>
                </StyledGrid>
                <StyledGrid container className="footer-bottom">
                  <StyledGrid class={classesFooter.containerCopyRight} item xs={9} sm={9} lg={9}>
                    <Copyright />
                  </StyledGrid>
                  <Hidden mdDown>
                    <StyledGrid item xs={3} sm={3} lg={3}>
                      <MethodsPayments />
                    </StyledGrid>
                  </Hidden>
                </StyledGrid>
              </div>
            </div>
          </StyledContainer>
        </StyledFooter>
      )}
      <SessionErrorDialog />
      <ConfirmationDialog />
    </>
  );
}

export default React.memo(Footer);
